import { Typography, Button, Menu, MenuItem, Checkbox, FormControlLabel } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    filterButtons: {
        width: "100%",
        minWidth: "120px",
        height: "43px",
        color: "rgba(0, 0, 0, .8)!important",
        paddingRight: "30px",
        marginBottom: "10px",
        textAlign: "left",
        border: "1px solid rgb(196, 196, 196)!important",
        borderRadius: "3px",
        borderBottom: "1px solid #757575!important",
        display: "block!important",
        '&:disabled': {
            color: "rgba(0, 0, 0, .3)!important",
        }
    },
    filterButtonsIcon: {
        position: "absolute",
        right: "2px",
        color: "rgba(0,0,0,.55)!important"
    },
}));

const SingleSelect = ({ title, displayText, options, disabled, onSelected, selected, closeOnSelect, styles, ...props }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(selected || null);

    useEffect(() => {
        if (!selected || selected == 'undefined' || selected == "") setSelectedOption(null);
        else if (selected) setSelectedOption(selected);
    }, [selected]);

    const handleMenuClose = useCallback((event) => {
        setAnchorEl(null);
        if (closeOnSelect != true) onSelected(selectedOption);
    }, [selectedOption, onSelected]);

    const handleMenuClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleOptionSelect = useCallback((option) => {
        setSelectedOption(option);
        if (closeOnSelect == true) {
            setAnchorEl(null);
            onSelected(option);
        }
    }, []);

    const renderDisplayText = () => {
        const selectedName = options.find(opt => opt.key == selectedOption)?.value;
        return selectedName || displayText;
    };

    return (
        <>
            {title && <Typography style={{ marginBottom: "5px" }}>{title}</Typography>}
            <Button
                variant="outlined"
                onClick={handleMenuClick}
                disabled={disabled}
                className={classes.filterButtons}
                {...props}
            >
                <Typography 
                    component="label"
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: '100%'
                    }}
                >
                    {renderDisplayText()}
                </Typography>
                <ArrowDropDownIcon className={classes.filterButtonsIcon} />
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        maxHeight: "300px",
                        width: anchorEl?.offsetWidth || "300px",
                    },
                }}
            >
                {options
                    .sort((a, b) => a.value.localeCompare(b.value))
                    .map(opt => (
                        <MenuItem dense key={opt.key}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={selectedOption === opt.key}
                                        onChange={() => { handleOptionSelect(opt.key) }}
                                        disabled={opt?.disabled == true ? true : false}
                                    />
                                }
                                label={opt.value}
                            />
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

export default SingleSelect;
