import React, { createContext, useContext, useEffect, useRef } from "react";
import socket from "../../hooks/useSocket";
import { AuthContext } from "./AuthContext";

const UserStatusContext = createContext();


// 2. Criar um componente Provider
const UserStatusProvider = ({ children }) => {
    const { user, handleStatus, state } = useContext(AuthContext);
    const statusRef = useRef('offline');
    const activityTimeout = useRef(null);
    let initialized = false;

    // Tempo para defiir ausência de usuário
    const outMinutes = 10;
    const handleActivity = () => {
        if (activityTimeout.current) clearTimeout(activityTimeout.current);
        if (statusRef.current !== 'online') {
            socket.emit('change_status', { userId: user.id, state: 'online', adminId: user.adminId });
            statusRef.current = 'online';
        }

        activityTimeout.current = setTimeout(() => {
            if (statusRef.current !== 'out') {
                socket.emit('change_status', { userId: user.id, state: 'out', adminId: user.adminId });
                statusRef.current = 'out';
            }
        }, outMinutes * 60 * 1000); // Usuário fica ausente após x minutes
    };

    useEffect(() => {
        if (initialized == true) return;
        initialized = true;

        if (user?.adminId) {
            socket.connect(user.adminId);
        };

        socket.on('connect', () => {
            socket.emit('register', { userId: user.id });
            handleActivity();
        });

        socket.on('disconnect', () => {
            if (activityTimeout.current) clearTimeout(activityTimeout.current);
            statusRef.current = 'offline';
        });

        socket.on(`user_status_${user.adminId}`, (data) => {
            console.info(`Usuário: [${data.id}]:[${data.name}] está ${data.state}`);
            if (data.id === user.id) {
                handleStatus(data.state)
            }
        });

        const handleMouseMove = () => handleActivity();
        const handleKeyDown = () => handleActivity();
        const handleBeforeUnload = () => socket.disconnect();
        const handleLoad = () => socket.emit('change_status', { userId: user.id, state: 'online', adminId: user.adminId });

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, [user]);

    return (
        <UserStatusContext.Provider>
            {children}
        </UserStatusContext.Provider>
    );
};

export default UserStatusProvider;