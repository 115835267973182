
import { Button, IconButton, Menu, MenuItem, Paper, Tooltip, makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import profileNoPic from "../../assets/blank.png";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import socket from "../../hooks/useSocket";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ContactConcludeTicket from "../ContactConcludeTicket"; //Concluir Atendimento
import ContactCreateTicketModal from "../ContactCreateTicketModal"; //Criar Ticket **DESABILITADO AINDA**
import ContactFlashMessenger from "../ContactFlashMessenger"; //Mensagens Rapidas
import ContactInfoModal from "../ContactInfoModal"; //informacoes de contato
import ContactTransferModal from "../ContactTransferModal"; //Transferencia de atendimento
import MessageInput from "../MessageInput";
import MessagesList from "../MessagesList";
import ForContactsModal from "../Tag/forContactsModal";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";

import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';

import classNames from 'classnames';
import './style.scss';
import ScheduleMessageListModal from "../ScheduleMessageListModal";
import GetUrlTicketPath from "../../helpers/GetUrlTicketPath";
import CheckComponentAccess from "../../helpers/CheckComponentAccess";
import ContactModal from "../ContactModal";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%"
    },
  },
  buttonBox: {
    [theme.breakpoints.down('959')]: {
      display: 'inline-flex',
      width: 'fit-content',
      float: 'right',
      height: '100%',
      paddingTop: '15px',
		},
  },
  buttonDesktop: {
    display: 'flex',
    paddingRight: '20px',
    [theme.breakpoints.down('810')]: {
      display: "none",
    }
  },
  buttonMobile: {
    display: 'none',
    position: "relative",
    right: "20px",
    top: "0px",
    [theme.breakpoints.down('810')]: {
      display: "flex",
    },
    [theme.breakpoints.down('500')]: {
      right: "0",
    },
    "& button": {
      padding: "0",
      margin: "0",
      top: "-10px",
    }
  },
  buttonsStyle: {
    borderRadius: "0px",
    borderColor: "black",
    color: "black",
    backgroundColor: "white",
    margin: "0px 5px 0px 5px",
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },
  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  filasTexto: {
    display: 'flex',
    padding: '27px',
    position: 'absolute',
    marginLeft: '28rem',
    '@media(max-width: 1250px)': {
      display: "none !important",
    }
  },
  icon: {
    width: '35px',
    height: '35px',
    margin: '0 2px',
    borderRadius: '100%',
    background: '#e0e0e0',
    color: '#0B708C',
    "&:hover": {
      background: '#bdbdbd'
    },
    "& svg": {
      width: '25px',
      height: '25px'
    },
  },
  header: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('959')]: {
      display: 'inline-block',
      position: 'relative',
      width: 'calc(100% - 65px)'
    },
  },
  flashOnIcon: {
    color: '#0B708C',
    background: '#e0e0e0',
    width: '13px!important',
    height: '13px!important',
    position: 'absolute!important',
    bottom: '5px!important',
    right: '3px!important'
  },
  flashOnIconDisabled: {
    color: '#c2c2c2',
    background: '#eeeeee'
  }
}));

const Ticket = ({ ticketId }) => {

  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [drawerContactInfoModalOpen, setDrawerContactInfoModalOpen] = useState(false);
  const [drawerTransferModalOpen, setDrawerTransferModalOpenOpen] = useState(false);
  const [drawerConcludeTicketOpen, setDrawerConcludeTicketOpen] = useState(false);
  const [drawerFlashMessengerOpen, setDrawerFlashMessengerOpen] = useState(false);
  const [scheduleMessageOpen, setScheduleMessageOpen] = useState(false);
  const [drawerCreateTicketModalOpen, setDrawerCreateTicketModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [forContactsModalOpen, setForContactsModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const urlTicketUUID = GetUrlTicketPath();

  const { adminId } = user;

  // modal de informações de contato
  const handleContactInfoOpen = () => {
    setContactModalOpen(true);
  }

  const handleContactInfoClose = () => {
    setContactModalOpen(false)
  }

  // Modal de agendamento de mensagens 
  const handleScheduleMessageOpen = () => {
    setScheduleMessageOpen(true);
  };

  const handleScheduleMessageClosed = () => {
    setScheduleMessageOpen(false);
  };

  // Modal Informacoes de Contato
  const handleContactInfoModalOpen = () => {
    setDrawerContactInfoModalOpen(true);
  };
  const handleContactInfoModalClose = () => {
    setDrawerContactInfoModalOpen(false);
  };

  // Modal Transferencia
  const handleDrawerTransferTicketOpen = () => {
    setDrawerTransferModalOpenOpen(true);
  };
  const handleTransferModalClose = () => {
    setDrawerTransferModalOpenOpen(false);
  };

  const handleDrawerConcludeTicketClose = () => {
    setDrawerConcludeTicketOpen(false);
  };

  // Modal Mensagens Rapidas
  const handleDrawerFlashMessengerOpen = () => {
    setDrawerFlashMessengerOpen(true);

  };
  const handleDrawerFlashMessengerClose = () => {
    setDrawerFlashMessengerOpen(false);
  };

  // Modal Criar Ticket **Desabilitado**
  const handleDrawerCreateTicketModalClose = () => {
    setDrawerCreateTicketModalOpen(false);
  };

  const handleOpenForContactsModal = () => {
    setForContactsModalOpen(true);
  };

  const handleCloseForContactsModal = () => {
    setForContactsModalOpen(false);
  };

  const handleDrawerConcludeTicketOpen = () => {
    setDrawerConcludeTicketOpen(true);
  };

  const handleAddForContactsModal = tagsIdReturn => {
    if (!tagsIdReturn || tagsIdReturn.length < 1) setContact({ ...contact, tagsId: null });
  };

  useEffect(() => {
    if (!ticketId || ticketId == 'undefined') return;
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/u/" + ticketId);
          const ct = data.Contact;
          setContact(ct);
          setTicket(data);
        } catch (err) {
          console.error({err});
          toastError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, user, history]);

  useEffect(() => {

    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on(`ticket-${adminId}`, (data) => {
      if (data.action === "update") {
        if (data.message?.ticket?.uuid === urlTicketUUID) {
          if (data.message?.ticket) setTicket(data.message.ticket);
          else if (data.ticket) setTicket(data.ticket);
        }
      }

      if (data.action === "delete" && ticket.id === data.ticketId) {
        if (data.message?.ticket?.uuid === urlTicketUUID) {
          history.push("/tickets");
        }
      }
    });

    socket.on(`contact-${adminId}`, (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => { };
  }, [ticketId, ticket, history]);

  useEffect(() => {

    if (!ticketId || ticketId == 'undefined') return;

    const ticketEvent = `ticket-${adminId}`;
    const ticketListener = async (data) => {
      if (data.action === "update") {
        // verificar se o ticket está aberto ou aguardando e possuí contato
        const { ticket } = data;

        if (ticket?.uuid == ticketId && ticket.Contact) {
          setContact(ticket.Contact);
          setTicket(ticket);
        }
      }
    };

    socket.on(ticketEvent, ticketListener);

    return () => {
      socket.off(ticketEvent, ticketListener);
    };
}, []);

  const QuickMessageIcon = ({ ...props }) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <ChatBubbleIcon {...props} />
        <FlashOnIcon
          className={classNames({
            [classes.flashOnIcon]: true,
            [classes.flashOnIconDisabled]: props?.disabled == true ? true : false,
          })}
        />
      </Box>
    );
  };  

  const transferToAwait = async () => {

    setLoading(true);

    let data = {};
    data.isCampaign = false;
    data.status = 'pending';
    // data.userId = user.id;
    // data.userIdTranfer = user.id;
    // data.transfer = 1;

    try {
      await api.put(`/tickets/${ticket.id}`, data);
      toast.success("Movido com sucesso!")
    } catch (err) {
      console.err(err)
    }
    setLoading(false);
    history.push(`/tickets`);
  }

  const RenderMessagesList = useMemo(() => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
        />
        <MessageInput ticketId={ticket.id} uuid={ticket.uuid} ticketStatus={ticket.status} contactId={ticket?.Contact?.id || ticket.contactId} whatsapp={ticket.whatsapp} ticketQueue={ticket.queueId} />
      </>
    )
  }, [ticket]);

  function retornaDados(ticket) {
    if (!ticket.Contact) return;

    const { whatsapp, Queue } = ticket;

    const getFilaText = () => {
      
      if (whatsapp?.moduleId == 2) {
        
        if (!Queue) return `<b style="display: block">${whatsapp.name}</b> Sem fila`;
        return `<b style="display: block">${whatsapp.name}</b> ${Queue.name}`;
        
      } else {
        
          if (ticket.Contact) {
            if (ticket) {
              if (ticket.whatsapp) {
                return `<b style="display: block">${ticket?.whatsapp?.name}</b> ${ticket.whatsapp.numberConnection}`;
              } else {
                return `<b style="display: block">Sem Conexão</b> ${ticket.whatsapp.numberConnection || ticket.numberConnection}`;
              }
            } else {
              return `<b style="display: block">Sem Conexão</b> ${ticket.whatsapp.numberConnection || ticket.numberConnection}`;
            }
          }
        
      }
    };

    const montarFila = getFilaText();
    const pegarImg = whatsapp?.picConnection || profileNoPic || '/media/avatars/blank.png';

    document.getElementById("picConnection").src = pegarImg;
    document.getElementById("definirFila").innerHTML = montarFila;
  }

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerContactInfoModalOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <Box
            className={classes.header}
          >
            {retornaDados(ticket)}
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleContactInfoModalOpen}
            />
            <Box
              className={classes.buttonBox}
            >
              {(ticket.status == "open" || ticket.status == "pending") && (
                <>
                  <div className={classes.buttonDesktop}>
                    <Tooltip title={i18n.t("messagesList.header.buttons.autoMessage")}>
                      <IconButton
                        className={classes.icon}
                        disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading}
                        onClick={() => { handleDrawerFlashMessengerOpen() }}
                        aria-label="transfer-ticket"
                      >
                        <QuickMessageIcon disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading} />
                      </IconButton>
                    </Tooltip>
                    {
                      CheckComponentAccess(user, 'customerRelationship') &&
                      <Tooltip title={i18n.t("messagesList.header.buttons.contactInfo")}>
                        <IconButton
                          className={classes.icon}
                          disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading}
                          aria-label="contact-info"
                          onClick={handleContactInfoOpen}
                        >
                          <PermContactCalendarOutlinedIcon 
                            disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading} 
                          />
                        </IconButton>
                      </Tooltip>
                    }
                    <Tooltip title={i18n.t("ticketOptionsMenu.linkTags")}>
                      <IconButton
                        className={classes.icon}
                        disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading}
                        onClick={handleOpenForContactsModal}
                        aria-label="transfer-ticket"
                      >
                        <LabelOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    {ticket.status == "open" && ticket.whatsapp.moduleId == 1 &&
                      <Tooltip title={i18n.t("ticketOptionsMenu.scheduleMessage")}>
                        <IconButton
                          className={classes.icon}
                          disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading}
                          onClick={handleScheduleMessageOpen}
                          aria-label="transfer-ticket"
                        >
                          <AlarmOutlinedIcon />
                        </IconButton>
                      </Tooltip>}
                    {ticket.status == "open" &&
                      <Tooltip title={i18n.t("ticketOptionsMenu.transferToAwait")}>
                        <IconButton
                          className={classes.icon}
                          disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading}
                          onClick={e => transferToAwait(e)}
                          aria-label="transfer-ticket"
                        >
                          <PauseCircleOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>}

                    <Tooltip title={i18n.t("ticketOptionsMenu.transfer")}>
                      <IconButton
                        className={classes.icon}
                        disabled={!ticket?.whatsapp?.status || String(ticket.whatsapp.status).toLowerCase() != 'connected' || loading}
                        onClick={handleDrawerTransferTicketOpen}
                        aria-label="transfer-ticket"
                      >
                        <SwapHorizIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={i18n.t("messagesList.header.buttons.conclude")}>
                      <IconButton
                        className={classes.icon}
                        disabled={!ticket?.whatsapp?.status || loading}
                        onClick={handleDrawerConcludeTicketOpen}
                        aria-label="close-ticket"
                      >
                        <HowToRegOutlinedIcon />
                      </IconButton>
                    </Tooltip>

                  </div >
                </>
              )}
            </Box>
          </Box>

        </TicketHeader>
        <ReplyMessageProvider>
          {RenderMessagesList}
        </ReplyMessageProvider>
      </Paper>
      <ScheduleMessageListModal
        ticketId={ticket?.id}
        contactId={contact?.id}
        connectionId={ticket?.whatsapp?.id}
        open={scheduleMessageOpen}
        onClose={handleScheduleMessageClosed}
        onConfirm={e => console.info(e)}
      />
      <ContactInfoModal
        open={drawerContactInfoModalOpen}
        handleDrawerClose={handleContactInfoModalClose}
        contact={contact}
        loading={loading}
        contactId={contact.id}
      />
      <ContactTransferModal
        open={drawerTransferModalOpen}
        handleDrawerClose={handleTransferModalClose}
        contact={contact}
        ticket={ticket}
        loading={loading}
        userTranfer={user.id}
      />
      <ContactConcludeTicket
        open={drawerConcludeTicketOpen}
        handleDrawerClose={handleDrawerConcludeTicketClose}
        contact={contact}
        ticketid={ticketId}
        ticket={ticket}
        loading={loading}
      />
      <ContactFlashMessenger
        open={drawerFlashMessengerOpen}
        handleDrawerClose={handleDrawerFlashMessengerClose}
        contact={contact}
        ticketid={ticket.id}
        ticket={ticket}
        loading={loading}
      />
      <ContactCreateTicketModal
        open={drawerCreateTicketModalOpen}
        handleDrawerClose={handleDrawerCreateTicketModalClose}
        contact={contact}
        ticketid={ticketId}
        initialContact={{}}
        loading={loading}
      />
      <ForContactsModal
        open={forContactsModalOpen}
        onClose={handleCloseForContactsModal}
        onSave={handleAddForContactsModal}
        aria-labelledby="form-dialog-title"
        tagsId={contact?.tagsId}
        contactId={contact.id}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleContactInfoClose}
        aria-labelledby="form-dialog-title"
        contactId={contact.id}
        // tags={tags.filter((tag) => tag.id >= 1)}
      />
    </div >
  );
};

export default Ticket;
