import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      "& .Mui-selected": {
        userSelect: "none",
        pointerEvents: "none",
        backgroundColor: `${theme.palette.primary.main}!important`,
        borderColor: `${theme.palette.primary.main}!important`,
        color: `${theme.palette.primary.contrastText}!important`,
      },
    },
  })
);

const CustomPagination = ({ totalPages, currentPage, onChange }) => {
  const classes = useStyles();

  return (
    <Box mt={2}>
      <Pagination
        count={totalPages}
        page={+currentPage}
        onChange={onChange}
        variant="outlined"
        shape="rounded"
        color="primary"
        className={classes.container}
      />
    </Box>
  );
};

export default CustomPagination;