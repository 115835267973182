import { BrowserRouter, Switch, Route as RouterRoute } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Campaign from "../pages/Campaign";
import BlackListCampaign from "../pages/Campaign/blackList";
import Chatbot from "../pages/Chatbot";
import Company from "../pages/Company/";
import CompanySignature from "../pages/CompanySignature";
import ConclusionMessages from "../pages/ConclusionMessages";
import Connections from "../pages/Connections/";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import DatabaseError from "../pages/Error/DatabaseError";
import Integrations from "../pages/Integration";
import Login from "../pages/Login/";
import MessagesAPI from "../pages/MessagesAPI/";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import RelatoryCampaign from "../pages/RelatoryCampaigns/";
import RelatoryGeneralCampaigns from "../pages/RelatoryGeneralCampaigns";
import RelatoryTags from "../pages/RelatoryTags";
import RelatoryTickets from "../pages/RelatoryTicket";
import Settings from "../pages/Settings/";
import Signup from "../pages/Signup/";
import Tags from "../pages/Tags";
import OpeningHours from "../pages/OpeningHours";
import Tickets from "../pages/Tickets/";
import Users from "../pages/Users";
import Route from "./Route";
import LoggedLayout from "../components/Layout/loggedLayout";
import PageNotFound from "../pages/Error/PageNotFound";
import Component from "../pages/Component";
import CustomerRelationship from "../pages/customerRelationship";
import WhatsappTemplate from "../pages/Connections/Whatsapp/template";

export const privateRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/chatbot', component: Chatbot },
  { path: '/connections', component: Connections },
  { path: '/contacts', component: Contacts },
  { path: '/conclusionMessages', component: ConclusionMessages },
  { path: '/companySignature', component: CompanySignature },
  { path: '/relatory/:id?', component: RelatoryCampaign },
  { path: '/ticketRelatory', component: RelatoryTickets },
  { path: '/tagRelatory', component: RelatoryTags },
  { path: '/company', component: Company },
  { path: '/users', component: Users },
  { path: '/tags', component: Tags },
  { path: '/openingHour', component: OpeningHours },
  { path: '/quickAnswers', component: QuickAnswers },
  { path: '/messages-api', component: MessagesAPI },
  { path: '/settings', component: Settings },
  { path: '/queues', component: Queues },
  { path: '/campaign', component: Campaign },
  { path: '/generalCampaignsRelatory', component: RelatoryGeneralCampaigns },
  { path: '/integrations', component: Integrations },
  { path: '/notFound', component: PageNotFound },
  { path: '/component', component: Component },
  { path: '/customerRelationship', component: CustomerRelationship },
  { path: '/connections/whatsapp/templates', component: WhatsappTemplate },
];

export const ticketRoutes = [
  { path: '/tickets/:ticketId?', component: Tickets },
  { path: '/tickets/broadcast/:broadcastId?', component: Tickets },
  { path: '/tickets/schedule/:contactId?', component: Tickets }
];

export const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/signup', component: Signup },
  { path: '/database/notFound', component: DatabaseError },
  { path: '/blackList/:adminId?/:campaignContactId?', component: BlackListCampaign }
];

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>

          {
            // renderizar rotas publicas
            publicRoutes.map(route => {
              return <Route key={route.path} exact path={route.path} component={route.component} />
            })
          }

          <WhatsAppsProvider>
            <Route exact path={privateRoutes.map(pvRoutes => pvRoutes.path)} isPrivate>
              <LoggedLayout>
                {privateRoutes.map(route => {
                  return <Route key={route.path} exact path={route.path} component={route.component} isPrivate />
                })}
              </LoggedLayout>
            </Route>
            <Route exact path={ticketRoutes.map(tRoutes => tRoutes.path)} isPrivate>
              <LoggedLayout>
                {ticketRoutes.map(route => {
                  return <Route key={route.path} exact path={route.path} component={route.component} isPrivate />
                })}
              </LoggedLayout>
            </Route>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
