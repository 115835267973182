import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import EmojiEmotions from '@material-ui/icons/EmojiEmotions';

import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";

import Box from "@material-ui/core/Box";
import ErrorIcon from '@material-ui/icons/Error';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import WifiTetheringSharpIcon from '@material-ui/icons/WifiTetheringSharp';
import ConfigureDays from "../../helpers/ConfigureDays";

import {
  Avatar,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Button,
  CircularProgress,
  IconButton,
  ListItemAvatar,
  makeStyles
} from "@material-ui/core";


import EmojiPicker from 'emoji-picker-react';
import { withStyles } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";
import { Block, Done, DoneAll, ExpandMore, GetApp } from "@material-ui/icons";

import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import MessageIcon from '@material-ui/icons/Message';
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import socket from "../../hooks/useSocket";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";
import MessageOptionsMenu from "../MessageOptionsMenu";
import ModalImageCors from "../ModalImageCors";
import SkeletonMessages from "./skeleton";
import { findDescriptionInAd, findImageInAd, findTitleInAd, findUrlInAd } from "../../helpers/FilterAdMessage";
import EmojiGroup from "../EmojiGroup";
import JsonParse from "../../helpers/JsonParse";
import classNames from "classnames";
import EditIcon from '@material-ui/icons/Edit';
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined';
import GetUrlTicketPath from "../../helpers/GetUrlTicketPath";
import ApiIcon from '@mui/icons-material/Api';

import { addItem, deleteItem, getAllItems, getItemById, updateItem } from "../../helpers/IndexedDb";
import { messages } from "../../translate/languages";

const useStyles = makeStyles((theme) => ({

  loadMoreButton: {
    color: "#fff",
    cursor: "pointer",
    padding: "2px 0px",
    zIndex: "2",
    alignItems: "center",
    userSelect: "none",
    borderRadius: "7px",
    justifyContent: "center",
    backgroundColor: "#0b708c",
    width: "100px",
    margin: "10px auto",
    position: "relative",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
    textTransform: "uppercase",
    textAlign: "center",
  },

  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  messagesList: {
    backgroundColor: "#efeae2",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    overflowX: "hidden",
    position: 'relative', // adicionar posição relativa ao contêiner
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    marginBottom: '2px',
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageLeftReaction: {
    marginRight: 20,
    marginTop: 2,
    marginBottom: 15,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  adContainerLeft: {
    maxWidth: '800px',
    margin: "-3px -80px 6px 0px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  adMsg: {
    padding: 10,
    maxWidth: 800,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  adSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  adContainerRight: {
    maxWidth: '800px',
    margin: "-3px -80px 6px 0px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  adMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  adSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  adTitle: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  quotedContainerLeft: {
    marginBottom: "10px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageRightReaction: {
    marginLeft: 20,
    marginBottom: 15,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    marginBottom: "10px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    bottom: 0,
    color: "#667781",
    position: "relative",
    right: "-70px",
    float: "right",
    margin: "5px 0",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: "#53bdeb",
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    width: "100%",
    padding: "5px 10px",
    textTransform: "lowercase",
    fontSize: "13px",
    minWidth: "200px",
    marginBottom: "10px",
    display: "block",
    "& .MuiButton-label": {
      position: "relative",
      padding: "10px 40px 0 30px",
      display: "inline-block",
      textOverflow: "ellipsis",
      wordWrap: "initial",
      whiteSpace: "nowrap",
      overflow: "hidden",
      height: "40px",
      lineHeight: "18px",
      "& .MuiButton-startIcon": {
        position: "absolute",
        top: "7px",
        left: "10px"
      },
      "& .MuiButton-endIcon": {
        padding: "3px",
        border: "1px solid #9e9e9e",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        textAlign: "center",
        position: "absolute",
        right: "10px",
        top: "2px",
        color: "#9e9e9e"
      }
    },
    "&:hover": {
      backgroundColor: "#0b708c",
      color: "#ffffff",
      "& .MuiButton-endIcon": {
        color: "#ffffff!important",
        borderColor: "#ffffff!important"
      }
    }
  },
  buttonsMessage: {
    width: "100%",
    padding: "12px",
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    color: "rgb(43, 115, 169)",
    fontWeight: "800"
  },
  contactsInChat: {
    position: "relative",
    width: "100%",
    padding: "10px",
    cursor: "pointer",
    background: "#eeeeee",
    borderRadius: "5px",
    "& .contactProfilePicture": {
      position: "relative",
      border: "3px solid #eeeeee"
    },
    "& label": {
      marginTop: "10px",
      fontWeight: "700",
      color: "#757575"
    }
  },
  contactsInChatPopover: {
    padding: "10px",
  },
  annotation: {
    background: '#FACB9F',
  }
}));

const reducer = (state, action) => {

  if (action.type == "LOAD_MESSAGES") {
    const messages = action.payload;
  
    const updatedMessages = new Map(state.map((message) => [message.id, message]));
  
    messages.forEach((message) => {
      updatedMessages.set(message.id, message);
    });
  
    return Array.from(updatedMessages.values()).sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  }

  if (action.type == "ADD_MESSAGE") {

    const newMessage = action.payload;

    const messageIndex = state.findIndex((m) => {
      return (newMessage.id !== 'await_undefined' && m.id == newMessage.id) || (newMessage.fromMe && !newMessage.Ticket?.isGroup && newMessage.awaitSendUuid != null && m.awaitSendUuid == newMessage.awaitSendUuid) || (m.messageUUID && newMessage.awaitSendUuid != null && m.messageUUID == newMessage.awaitSendUuid)
    });

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    // filtrar e ordenar mensagens
    const filterByUuid = (array) => {
      const uuids = {};
      const result = array.filter(obj => {
        if (uuids[obj.id]) {
          return false;
        } else {
          uuids[obj.id] = true;
          return true;
        }
      });

      return result;
    }
    state = filterByUuid(state);
    state = state.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    return [...state];
  }

  if (action.type == "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;

    const messageIndex = state.findIndex((m) => (messageToUpdate.id !== 'await_undefined' && m.id == messageToUpdate.id) || (messageToUpdate.fromMe && m.timestamp == messageToUpdate.timestamp));

    if (messageToUpdate?.annotation == true && messageToUpdate?.isDeleted == true && messageIndex !== -1) {
      return state.filter(item => item.id != messageToUpdate.id);
    }
    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
      return [...state];
    } else {
      return state;
    }
  }

  if (action.type == "RESET") {
    return [];
  }
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const MessagesList = ({ ticket, ticketId, isGroup }) => {

  const classes = useStyles();

  const [messagesArray, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [notUpdatePage, setNotUpdatePage] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const { user } = useContext(AuthContext);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  const urlTicketPath = GetUrlTicketPath();

  // variaveis para renderizar contatos anexados
  const [contactAnchorEl, setContactAnchorEl] = useState(null);
  const contactOpen = Boolean(contactAnchorEl);
  const contactId = contactOpen ? 'contact-simple-popover' : undefined;
  const [contacts, setContacts] = useState([]);
  const history = useHistory();

  // Criação da referência para a box externa
  const outerBoxRef = useRef(null);

  // Variaveis para renderização de emoji
  const [emojiMessageId, setEmojiMessageId] = useState(null);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });

  function handleFindIsReaction(currentPosition) {

    const checkElementExist = () => {
      let isReaction = document.querySelectorAll(".EmojiPickerReact");
      if (!isReaction || isReaction.length == 0) return;
      isReaction = isReaction[0]?.style?.height !== '450px';
      if (!isReaction || isReaction == null) {
        const { top, left } = currentPosition;
        // necessário para criar um reposicionamento do elemento
        setAnchorPosition({ top: top - 5, left: left - 5 });
        setTimeout(() => setAnchorPosition({ top: top, left: left }), 500);
      } else {
        setTimeout(checkElementExist, 500);
      }
    };
    checkElementExist();
  }

  const handleEmojiClick = (event, fromMe, messageId) => {
    const rect = event.currentTarget.getBoundingClientRect();

    const positionVertical = ((rect.top + rect.height) - 100);
    const positionHoritonzal = (rect.left - 140);

    const propsPosition = {
      top: (positionVertical <= 300 ? positionVertical + 100 : positionVertical),
      left: positionHoritonzal
    };

    setEmojiMessageId(messageId);
    setAnchorPosition(propsPosition);
    setEmojiAnchorEl(event.currentTarget);

    setTimeout(() => handleFindIsReaction(propsPosition), 500);
  };

  const handleEmojiClose = () => {
    setEmojiAnchorEl(null);
  };

  const sendReaction = async (emoji, messageId) => {
    try {
      await api.post(`/messages/reaction/${messageId}`, { reaction: emoji || "" });
    } catch (err) {
      console.error(err)
    } finally {
      setEmojiMessageId(null);
    }
  };

  const onEmojiClick = async (event, emojiObject) => {
    handleEmojiClose();
    sendReaction(event?.emoji, emojiMessageId)
  };

  useEffect(() => {

    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  // Função para mover o scroll para uma box interna específica
  const scrollToBox = (selector) => {
    // Acessa a box externa
    const outerBox = outerBoxRef.current;

    // Resgatar 
    const innerBox = outerBox.querySelector(selector);

    // Verifica se a box interna existe
    if (innerBox) {
      // Move o scroll da box externa para a posição da box interna
      outerBox.scrollTop = innerBox.offsetTop;
    }
  }

  const updateLocalMessages = async (indexedDbKey, ms, log = false) => {

    const localValues = await getAllItems(user?.adminId);
    let pg = localValues.find((lv) => lv.key == indexedDbKey);
    if (pg) {
      let {messages, ticket} = pg.value;

      for (const msg of ms) {
        if (ticket?.id && ticket.id != msg.ticketId) continue;
        // verificar se mensagem existe, se não adiciona-la
        const messageIndex = messages.findIndex((m) => {
          return (msg.id !== 'await_undefined' && m.id == msg.id) || (msg.fromMe && !msg.Ticket?.isGroup && msg.awaitSendUuid != null && m.awaitSendUuid == msg.awaitSendUuid) || (m.messageUUID && msg.awaitSendUuid != null && m.messageUUID == msg.awaitSendUuid)
        });

        if (messageIndex !== -1) {
          messages[messageIndex] = msg;
        } else {
          messages.push(msg);
        }
      }

      await updateItem(user.adminId, pg.id, { ...pg.value, messages });
    }
  }

  useEffect(() => {
    if(!ticketId) return;
    
    if (notUpdatePage == true) {
      setNotUpdatePage(false);
      return;
    }

    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        // armazenar id da ultima mensagem para voltar o scroll posteriormente
        const firstMessage = document.querySelector('#messagesList [data-message="1"]');

        try {

          if (typeof ticketId !== 'string' && typeof ticketId !== 'number') return; // Verificação de tipo

          // verificar se o ticketId é 0 para enviar o contactId e pesquisar mensagens
          const isNewTicket = Number(ticketId) == 0;
          let params = { pageNumber };
          if (isNewTicket) params.contactId = ticket?.Contact?.id || '';

          // verificar se as mensagens existem localmente
          const pages = [];
          const indexedDbKey = `listMessages-${user?.adminId}-ticketId${ticketId}-contactId${params?.contactId}-user${user.id}-pageNumber`;
          if (pageNumber == 1) {
            const localValues = await getAllItems(user?.adminId);
            if (localValues) localValues.map(lv => { if (lv.key.includes(indexedDbKey)) pages.push(lv); }).sort();
          }
          let data;

          if (pages.length > 0) {

            for (let x = (pages.length - 1); x >= 0; x--) {
              const isLast = x == pages.length - 1;
              data = {
                count: isLast ? pages[x].value.count : data.count,
                hasMore: isLast ? pages[x].value.hasMore : data.hasMore,
                messages: data?.messages ? [...data.messages, ...pages[x].value.messages] : pages[x].value.messages,
                ticket: isLast ? pages[x].value.ticket : data.ticket
              };
            }

            // setar ultima pagina
            if (pages.length > 1) {
              setNotUpdatePage(true);
              setPageNumber(pages.length);
            }

            if (currentTicketId.current == ticketId) {
              dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
              setHasMore(data.hasMore);

              // verificar se há novas mensagens
              if (data?.messages && data.messages.length > 0 && ticketId != "00000") {
                const messagesFromTicket = data.messages.filter(msg => msg.ticketId === ticketId).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                const messageIds = messagesFromTicket.map(msg => msg.id);
                const { data: lastMessages } = await api.post(`/messages/getLastMessages/${ticketId}/${messagesFromTicket[messagesFromTicket.length - 1].id}`, { 
                  messageIds: messageIds && messageIds.length > 0 ? messageIds : [],
                  oldMessageCreatedAt: messagesFromTicket[0]?.createdAt || null
                });
                if (Array.isArray(lastMessages) && lastMessages.length > 0) {
                  for (const lm of lastMessages) {
                    dispatch({ type: "ADD_MESSAGE", payload: lm });
                  }
                  // atualizar banco local
                  await updateLocalMessages(`${indexedDbKey}-1`, lastMessages);
                }
              }

              // recarregar as mensagens do banco para certificar que está tudo atualizado (do mais recente para o mais antigo)
              (async () => {
                const ms = data.messages.filter((m) => m.ticketId == ticketId).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                const { data: updatedMessages } = await api.post(`/messages/checkMessagesStatus`, { messagesId: ms.map(m => m.id) });
                if (updatedMessages && Array.isArray(updatedMessages) && updatedMessages.length > 0) {
                  // atualizar mensagens
                  for (const um of updatedMessages) {
                    dispatch({ type: "ADD_MESSAGE", payload: um });
                  }
                  // atualizar banco local
                  await updateLocalMessages(`${indexedDbKey}-1`, updatedMessages);
                }
              })();
            }
          } else {
            const { data: messagesData } = await api.get("/messages/" + ticketId, { params });
            data = messagesData;
        
            await addItem({ key: `${indexedDbKey}-${pageNumber}`, value: data }, user?.adminId);
        
            // const { data: uuids } = await api.get(`/tickets_uuid/${ticket?.Contact?.id}`);
        
            if (currentTicketId.current == ticketId) {
              dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
              setHasMore(data.hasMore);
            }
          }

          if (pageNumber == 1 && data.messages.length > 1) scrollToBottom();
        } catch (err) {
          console.error({ err });
					toastError({ response: { data: { error: err?.response?.data?.message || err?.message || 'INTERNAL_ERROR' } } });
        } finally {
          if (pageNumber > 1 && firstMessage) {
            scrollToBox(`#${firstMessage.id}`);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    if (!ticketId || !ticket) return;
  
    const preMessageEvent = `preMessage-${user.adminId}`;
    const appMessageEvent = `appMessage-${user.adminId}`;
  
    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));
  
    const preMessageListener = async (data) => {
  
      let urlTicket = GetUrlTicketPath();
      if (data.ticket.uuid != urlTicket) return;
  
      const indexedDbKey = `listMessages-${user?.adminId}-ticketId${data.ticket}-contactIdundefined-user${user.id}-pageNumber-1`;
  
      // Dispara evento de mensagem ficticia
      var dateActual = new Date().toISOString();
      const message = {
        id: `await_${data?.messageUUID}`,
        ack: 1,
        body: data.body,
        fromMe: true,
        quotedMsg: data?.reqBody?.quotedMsg,
        timestamp: String(Date.now()),
        isDeleted: false,
        mediaName: null,
        mediaType: data?.mediaType ? data.mediaType : "extendedTextMessage",
        mediaUrl: data?.mediaUrl ? data.mediaUrl : null,
        participant: "",
        Ticket: data.ticket,
        createdAt: dateActual,
        updatedAt: dateActual,
        awaitSendUuid: data?.messageUUID,
        annotation: data?.annotation === true,
      };
  
      dispatch({ type: "ADD_MESSAGE", payload: message });
  
      await updateLocalMessages(indexedDbKey, [message], true);
  
      scrollToBottom();
    };
  
    const appMessageListener = async (data) => {
      if (data.action == "create") {
  
        let urlTicket = GetUrlTicketPath();
        if (data.message?.Ticket?.uuid == urlTicket && data.message?.Ticket?.id == ticketId) {
          if (!data?.message?.fromMe) {
            try {
              api.post(`/tickets/resetUnreadMessages`, { ticketId: data.ticket.id });
            } catch (err) {
              console.error({ err });
              toastError(err);
            }
            scrollToBottom();
          }
  
          dispatch({ type: "ADD_MESSAGE", payload: data.message });
  
          const indexedDbKey = `listMessages-${user?.adminId}-ticketId${ticketId}-contactIdundefined-user${user.id}-pageNumber-1`;
  
          await updateLocalMessages(indexedDbKey, [data.message]);
        }
      }
  
      if (data.action == "update") {
  
        let urlTicket = GetUrlTicketPath();
        if (data.message?.Ticket?.uuid == urlTicket) {
          dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
  
          const indexedDbKey = `listMessages-${user?.adminId}-ticketId${data.message.Ticket.id}-contactIdundefined-user${user.id}-pageNumber-1`;
  
          await updateLocalMessages(indexedDbKey, [data.message]);
        }
      }
    };
  
    socket.on(preMessageEvent, preMessageListener);
    socket.on(appMessageEvent, appMessageListener);
  
    return () => {
      socket.off(preMessageEvent, preMessageListener);
      socket.off(appMessageEvent, appMessageListener);
    };
  }, [ticketId, ticket]);
  

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  async function sendMesseger(messageData) {
    try {
      await api.post(`/messages/${ticket.id}`, messageData);
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  }

  function truncateText(text, maxLength) {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  const checkAdMessage = (message) => {
    if (message?.dataJson) {

      const messageFormat = JsonParse(message.dataJson);
      if (messageFormat == null) return;
      const { message: msg } = messageFormat;
      const extendedTextMessage = msg?.extendedTextMessage;
      const link = findUrlInAd(extendedTextMessage);

      if (link) {
        const handleDivClick = () => {
          window.open(link, '_blank');
        };

        const imageUrl = findImageInAd(extendedTextMessage);
        const title = findTitleInAd(extendedTextMessage);
        const description = findDescriptionInAd(extendedTextMessage);

        if (!imageUrl && !title && !description) return;

        return (
          <div
            onClick={handleDivClick}
            style={{ width: '100%', cursor: 'pointer' }}
            className={message.fromMe ? classes.adContainerRight : classes.adContainerLeft}
          >
            {(imageUrl) &&
              <img
                src={imageUrl}
                alt="adMessage"
                style={{ marginLeft: '5px', width: '90px', height: '90px' }}
              />
            }
            <div className={classes.adMsg}>
              {!message.quotedMsg?.fromMe && (
                <span className={classes.adTitle}>
                  {truncateText(title, 60)}
                </span>
              )}
              <p>{truncateText(description, 130)}</p>
            </div>
          </div>
        );
      }
    }
  };

  const checkMessageMedia = (message) => {

    if (message.mediaType == "locationMessage" || message.mediaType == 'liveLocationMessage') {
      const data = JsonParse(message.dataJson);
      const location = data?.message?.locationMessage
      return (<div>
        {
          location ? 
            <iframe
              width="500"
              height="200"
              style={{ borderColor: '#ff000000' }}
              src={`https://maps.google.com/maps?q=${location.degreesLatitude},${location.degreesLongitude}&t=&z=15&ie=UTF8&iwloc=&output=embed `}
            />
          : 
            "No Location Data..."
        }
      </div>);
    }

    if (message.mediaType == "image") {
      return <ModalImageCors
        imageUrl={message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`}
      />;
    }

    if (message.mediaType == "audio") {
      const audioSrc = message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`;
      return (
        <audio key={audioSrc} controls>
          <source
            src={audioSrc}
            type="audio/ogg">
          </source>
        </audio>
      );
    }

    if (message.mediaType == "videoMessage" || message.mediaType == "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`}
          controls
        />
      );
    } else if ((message?.driveUrl && String(message?.driveUrl).trim() != '') || (message?.mediaUrl && String(message?.mediaUrl).trim() != '')) {
      return (
        <>
          <div>
            <Tooltip
              arrow
              title={<Typography fontSize={20}>Download</Typography>}
            >
              <Button
                color="primary"
                variant="outlined"
                target="_blank"
                className={classes.downloadMedia}
                href={message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`}
              >
                <Box style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                  <InsertDriveFileIcon />
                  {message?.mediaName ? message.mediaName : 'Download'}
                </Box>
                <Box style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                  <GetApp />
                </Box>
              </Button>
            </Tooltip>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const renderReactions = (message) => {
    return (<EmojiGroup reactions={JsonParse(message.reaction)} message={message} onRemove={() => sendReaction("", message.id)} max={4} />);
  };

  const renderMessageAck = (message) => {
    let ackIcon;
    let ackTooltip = [];
    let onlytext;

    const { ackHanded, ackRead, ackSent, editedAt, annotation, scheduling, isApi } = message;

    const othersAck = annotation == true || scheduling == true || isApi == true;

    if (ackSent && !othersAck) {
      ackTooltip.push(
        <Typography key="ackSent" style={{ fontSize: "12px" }}>
          {i18n.t("ack.sent")} - {format(parseISO(ackSent), "dd/MM/yyyy HH:mm")}
        </Typography>
      );
    }
    if (ackHanded && !othersAck) {
      ackTooltip.push(
        <Typography key="ackHanded" style={{ fontSize: "12px" }}>
          {i18n.t("ack.serverAck")} - {format(parseISO(ackHanded), "dd/MM/yyyy HH:mm")}
        </Typography>
      );
    }
    if (ackRead && !othersAck) {
      ackTooltip.push(
        <Typography key="ackRead" style={{ fontSize: "12px" }}>
          {i18n.t("ack.read")} - {format(parseISO(ackRead), "dd/MM/yyyy HH:mm")}
        </Typography>
      );
    }
    if (editedAt && !othersAck) {
      ackTooltip.push(
        <Typography key="ackRead" style={{ fontSize: "12px" }}>
          {'Editada'} - {format(parseISO(editedAt), "dd/MM/yyyy HH:mm")}
        </Typography>
      );
    }

    if (message?.isApi == true) {
      ackIcon = <ApiIcon fontSize="small" className={classes.ackIcons} />;
      onlytext = i18n.t("ack.isApi");
    } else if (message?.annotation == true) {
      ackIcon = <EditIcon fontSize="small" className={classes.ackIcons} />;
      onlytext = i18n.t("ack.annotation");
    } else if (message?.scheduling == true) {
      ackIcon = <AlarmOutlinedIcon fontSize="small" className={classes.ackIcons} />;
      onlytext = message?.sentBy?.name ? `${i18n.t("ack.schedulingBy")} ${message.sentBy.name}` : i18n.t("ack.scheduling");
    } else {
      switch (message.ack) {
        case 0:
          ackIcon = <ErrorIcon fontSize="small" className={classes.ackIcons} />;
          onlytext = i18n.t("ack.error");
          break;
        case 1:
        case 2:
          ackIcon = <Done fontSize="small" className={classes.ackIcons} />;
          onlytext = i18n.t("ack.sent");
          break;
        case 3:
          ackIcon = <DoneAll fontSize="small" className={classes.ackIcons} />;
          onlytext = i18n.t("ack.serverAck");
          break;
        case 4:
        case 5:
          ackIcon = <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
          onlytext = i18n.t("ack.read");
          break;
        default:
          ackIcon = <ErrorIcon fontSize="small" className={classes.ackIcons} />;
          onlytext = i18n.t("ack.error");
      }
    }

    return (
      <HtmlTooltip
        arrow
        title={
          <React.Fragment>{ackTooltip.length == 0 ? onlytext : ackTooltip}</React.Fragment>
        }
      >
        {ackIcon}
      </HtmlTooltip>
    );
  };

  const renderMessageBrodcastAck = (message) => {
    if (message.remoteJid && message.remoteJid.includes('@broadcast')) {
      return <Tooltip
        title={<Typography style={{ fontSize: "12px" }}>{i18n.t("ack.broadcast")}</Typography>}
      >
        <WifiTetheringSharpIcon fontSize="small" className={classes.ackIcons} style={{ paddingBottom: "2px" }} />
      </Tooltip>;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index == 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {ConfigureDays(format(parseISO(messagesArray[index].createdAt), "yyyy/MM/dd"))}
          </div>
        </span >
      );
    }
    if (index < messagesArray.length - 1) {
      let messageDay = parseISO(messagesArray[index].createdAt);
      let previousMessageDay = parseISO(messagesArray[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {ConfigureDays(format(parseISO(messagesArray[index].createdAt), "yyyy/MM/dd"))}
            </div>
          </span>
        );
      }
    }
    if (index == messagesArray.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesArray.length && index > 0) {
      let messageUser = messagesArray[index].fromMe;
      let previousMessageUser = messagesArray[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        />
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.Contact?.name}
            </span>
          )}
          {(message.quotedMsg?.mediaUrl || message.quotedMsg?.driveUrl || (message.quotedMsg?.mediaType == "locationMessage" || message.quotedMsg?.mediaType == 'liveLocationMessage')) && checkMessageMedia(message.quotedMsg)}
          {message.quotedMsg?.mediaType != 'audio' && message.quotedMsg?.body}

          {(message.quotedMsg?.mediaType == 'buttonsMessage' && message.quotedMsg?.dataJson) && renderMessageButtons(message.quotedMsg?.dataJson)}
        </div>
      </div>
    );
  };

  const renderGroupContact = (isGroup, message) => {
    if (isGroup && isGroup == true || ticket?.Contact?.isGroup == true) {
      return (
        <span className={classes.messageContactName}>
          {message.Contact?.name || ''}
        </span>
      )
    } else {
      return (<></>);
    }
  }

  const renderGroupProfilePicture = (message) => {
    const url = message?.fromMe == false ? message?.Contact?.profilePicUrl : message?.Ticket?.whatsapp?.picConnection;
    return (
      <Avatar 
        alt="Profile Picture" 
        src={url}
        style={{
          marginRight: message?.fromMe == false ? 5 : 0,
          marginLeft: message?.fromMe == false ? 0 : 5
        }}
      />
    );
  }

  const renderMessageButtons = (data) => {
    data = typeof data == 'string' ? JsonParse(data) : data;
    let buttons = [];
    if (data.message?.buttonsMessage?.buttons) {
      const timestamp = new Date().getTime();
      data.message.buttonsMessage.buttons.map((b) => {
        buttons.push(
          <Tooltip
            arrow
            title={<Typography fontSize={20}>Selecionar botão</Typography>}
          >
            <Box
              key={`bt${timestamp}${b.buttonId}`}
              className={[classes.messageLeft, classes.buttonsMessage]}
              onClick={async () => {
                const message = {
                  read: 1,
                  fromMe: true,
                  mediaInBody: "",
                  body: b.buttonId,
                  cleanMsg: b.buttonId.trim(),
                  quotedMsg: null,
                };

                await sendMesseger(message)
              }}
            >
              {b.buttonText?.displayText}
            </Box>
          </Tooltip>
        );
      });
    }

    return buttons;
  };

  // funções para identificar contatos anexados
  const handleContactClick = (event, cts) => {
    setContactAnchorEl(event.currentTarget);
    setContacts(cts);
  };

  const handleContactClose = () => {
    setContactAnchorEl(null);
    setTimeout(() => {
      setContacts([]);
    }, 500);
  };

  const handleSaveContact = async (contact) => {
    let dt = {
      "adminId": ticket.whatsapp?.adminId || null,
      "connection": ticket.whatsapp?.numberConnection || null,
      "queueId": ticket.Queue?.id || null,
      "name": contact.name,
      "number": contact.number,
      "forAll": 1,
      "profilePicUrl": contact.profilePic,
      "isStartTicket": 1,
      "tagsId": JSON.stringify([])
    }

    try {
      const { data } = await api.post("/contacts", dt);
      if (data?.uuid) history.push(`/tickets/${data.uuid}`);
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  const renderContactList = (ms) => {
    if (String(ms.mediaType).toLowerCase() != 'contactmessage' && String(ms.mediaType).toLowerCase() != 'contactsarraymessage') return;
    if (!ms?.contacts) return;

    const cts = JsonParse(ms.contacts);
    if (!cts || !Array.isArray(cts) || cts.length == 0) return;
    let avatarImages = [];
    let contactNames = cts.length > 1 ? `${cts[0].name} e ${cts.length - 1} outro(s)` : cts[0].name;
    for (let x = 0; x < 3; x++) {
      if (!cts[x]) break;
      avatarImages.push(<Avatar
        alt="contact profile picture"
        src={cts[x].profilePic || ''}
        className="contactProfilePicture"
        style={{
          position: x == 0 ? "relative" : "absolute",
          top: x == 0 ? 0 : "10px",
          left: x == 0 ? 0 : `${35 * x - 10 * (x - 1)}px`,
          zIndex: x == 0 ? 3 : x == 1 ? 2 : 1
        }}
      />)
    }

    return (
      <Box component="div">
        <Box
          aria-describedby={contactId}
          onClick={(event) => handleContactClick(event, cts)}
          className={classes.contactsInChat}
        >
          {avatarImages}
          <Typography component="label">{contactNames}</Typography>
        </Box>
      </Box>
    );
  };

  const renderEmojiPicker = (message, index) => {
    return (
      <IconButton
        id={`emoji-${message.id}`}
        style={{
          [message.fromMe ? 'left' : 'right']: '-40px',
          bottom: '0px',
          position: 'absolute',
          display: 'none',
        }}
        aria-label="emoji"
        onClick={(e) => handleEmojiClick(e, message.fromMe, message.id)}
      >
        <EmojiEmotions />
      </IconButton>
    );
  };

  const handleMouseEnter = (messageId) => {
    const element = document.getElementById(`emoji-${messageId}`);
    if (element) {
      element.style.display = 'block';
    }
  };

  const handleMouseLeave = (messageId) => {
    const element = document.getElementById(`emoji-${messageId}`);
    if (element) {
      element.style.display = 'none';
    }
  };

  const renderMessages = () => {
    if (messagesArray.length > 0) {
      const viewMessagesList = messagesArray.map((message, index) => {
        if (!message.fromMe) {
          return (
            <>
              {renderDailyTimestamps(message, index)}
              <Box
                onMouseEnter={() => handleMouseEnter(message.id)}
                onMouseLeave={() => handleMouseLeave(message.id)}
                style={{
                  display: 'flex',
                  position: 'relative',
                  marginBottom: 'unset'
                }}
                key={message.id}
                id={`msg-${message.id}`}
                data-message="1"
              >
                {isGroup == true && renderGroupProfilePicture(message)}
                {renderMessageDivider(message, index)}
                <div className={message?.reaction ? classes.messageLeftReaction : classes.messageLeft} id={`component-msg-${message.id}`}>
                  {(ticket?.whatsapp?.moduleId == 1 || ticket?.whatsapp?.moduleId == 3) && renderEmojiPicker(message, index)}
                  <div style={{ display: 'flex', padding: '5px' }}>
                    <small style={{ marginRight: '20px' }}>
                      {format(parseISO(message.createdAt), "dd/MM/yyyy HH:mm")}{message.isEdited ? ' - Editada' : ''}
                    </small>
                  </div>
                  {(ticket?.whatsapp?.moduleId == 1 || ticket?.whatsapp?.moduleId == 3) && (
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted == true}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}
                  {renderGroupContact(isGroup, message)}
                  {checkAdMessage(message)}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {(message.mediaUrl || message.driveUrl || (message.mediaType == "locationMessage" || message.mediaType == 'liveLocationMessage')) && checkMessageMedia(message)}
                  {renderContactList(message)}
                  <div className={classes.textContentItem}>
                    <MarkdownWrapper>{String(message.mediaType).toLowerCase() != 'contactmessage' && String(message.mediaType).toLowerCase() != 'contactsarraymessage' && message.body}</MarkdownWrapper>
                    <span className={classes.timestamp}>
                      {renderMessageBrodcastAck(message)}
                    </span>
                    {message?.reaction && renderReactions(message)}
                  </div>
                </div>
                {(message.mediaType == 'buttonsMessage' && message.dataJson) && renderMessageButtons(message.dataJson)}
                <div style={{ flex: "1 1 0%" }} />
              </Box>
            </>

          );
        } else {
          return (
            <>
              {renderDailyTimestamps(message, index)}
              <Box
                onMouseEnter={() => handleMouseEnter(message.id)}
                onMouseLeave={() => handleMouseLeave(message.id)}
                style={{
                  display: 'flex',
                  position: 'relative',
                  marginBottom: 'unset',
                }}
                key={message.id}
                id={`msg-${message.id}`}
                data-message="1"
              >
                {renderMessageDivider(message, index)}
                <div style={{ flex: "1 1 0%" }} />
                <div 
                  className={classNames({
                    [classes.messageRightReaction]: message?.reaction ? true : false,
                    [classes.messageRight]: message?.reaction ? false : true,
                    [classes.annotation]: message?.annotation == true ? true : false
                  })}
                  id={`component-msg-${message.id}`}
                >
                  {(ticket?.whatsapp?.moduleId == 1 || ticket?.whatsapp?.moduleId == 3) && message?.annotation != true && renderEmojiPicker(message, index)}
                  <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px' }}>
                    {
                      (message?.annotation == true && message?.sentBy?.name) &&
                      <small style={{ let: '10px', marginRight: '20px', fontWeight: '800' }}>{message.sentBy.name}</small>
                    }
                    <small style={{ marginRight: '20px' }}>
                      {format(parseISO(message.createdAt), "dd/MM/yyyy HH:mm")}{message.isEdited ? ' - Editada' : ''}
                    </small>
                  </div>
                  {(ticket?.whatsapp?.moduleId == 1 || ticket?.whatsapp?.moduleId == 3) && (
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted == true}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}
                  {checkAdMessage(message)}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {(message.mediaUrl || message.driveUrl || (message.mediaType == "locationMessage" || message.mediaType == 'liveLocationMessage')) && checkMessageMedia(message)}
                  {renderContactList(message)}
                  <div
                    className={clsx(classes.textContentItem, {
                      [classes.textContentItemDeleted]: message.isDeleted == true,
                    })}
                  >
                    {message.isDeleted == true && (
                      <Block
                        color="disabled"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    )}
                    <MarkdownWrapper>{String(message.mediaType).toLowerCase() != 'contactmessage' && String(message.mediaType).toLowerCase() != 'contactsarraymessage' && message.body}</MarkdownWrapper>
                    <span className={classes.timestamp}>
                      {renderMessageAck(message)}
                    </span>
                    {message?.reaction && message?.annotation != true && renderReactions(message)}
                  </div>
                </div>
                {isGroup == true && renderGroupProfilePicture(message)}
              </Box>
            </>

          );
        }
      });
      return viewMessagesList;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        ref={outerBoxRef}
      >
        {hasMore && messagesArray.length > 0 && (
          <div
            className={classes.loadMoreButton}
            onClick={loadMore}
            disabled={loading}
          >
            {loading ? <CircularProgress color="inherit" size={16} /> : i18n.t("chat.loadMore")}
          </div>
        )}
        {renderMessages()}
      </div>
      <Popover
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        open={Boolean(emojiAnchorEl)}
        anchorEl={emojiAnchorEl}
        onClose={handleEmojiClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
      >
        <EmojiPicker onReactionClick={(e) => onEmojiClick(e)} onEmojiClick={(e) => onEmojiClick(e)} reactionsDefaultOpen={true} />
      </Popover>
      <Popover
        id={contactId}
        open={contactOpen}
        anchorEl={contactAnchorEl}
        onClose={handleContactClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.contactsInChatPopover }}
      >
        <Box component="div">
          <List component="nav" aria-label="secondary mailbox folders">
            {
              contacts.map(c => {
                return (
                  <ListItem button>
                    <ListItemAvatar>
                      <Avatar
                        alt="contact profile picture"
                        src={c.profilePic || ''}
                        className="contactProfilePicture"
                      />
                    </ListItemAvatar>
                    <ListItemText primary={c.name || ''} secondary={c.number || ''} />
                    <ListItemSecondaryAction>
                      <Tooltip
                        arrow
                        title={<Typography fontSize={20}>Iniciar atendimento</Typography>}
                      >
                        <IconButton edge="end" aria-label="message" onClick={() => handleSaveContact(c)}>
                          <MessageIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>)
              })
            }
          </List>
        </Box>
      </Popover>
    </div>
  );
};
export default MessagesList;