import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TagCheckList from '../Tag/tagCheckList';
import JsonParse from '../../helpers/JsonParse';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckComponentAccess from '../../helpers/CheckComponentAccess';
import MultiSelect from '../InputStyled/multiselect';
import SingleSelect from '../InputStyled/singleSelect';
import InputMask from 'react-input-mask';
import GetDate from '../../helpers/GetDate';
import CommentTab from '../CustomerRelationship/Tabs/Comment';

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	select: {
		padding: 0,
		"& ul": {
			padding: 5,
		},
		"& li": {
			padding: 5,
			fontSize: 12,
		},
	},
	tab: {
		backgroundColor: "transparent",
		color: "#000",
		boxShadow: "none"
	}
}));


const ContactSchema = Yup.object().shape({
	name: Yup.string().required("Name is required")
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave, byContactPage = true, tags, customerRelationshipAccess }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const [whatsapp, setWhatsapp] = useState();
	const { user: loggedInUser } = useContext(AuthContext);
	const [crmAccess, setCrmAccess] = useState(false);
	const initialState = {
		adminId: loggedInUser.adminId,
		name: "",
		number: "",
		email: "",
		forAll: true,
		rg: "",
		empresa: "",
		cpf: "",
		connection: "",
		nasc: "",
		endereco: "",
		extraInfo: [],
		queueId: "",
		opportunity: "",
		recurringValue: "0,00",
		singleValue: "0,00",
		contractTime: null,
		contractValue: "0,00",
		expirationData: null,
		modulesId: "1"
	};
	const [contact, setContact] = useState(initialState);
	const [isStartTicket, setIsStartTicket] = useState(false);
	const [tabValue, setTabValue] = React.useState(0); // para abas do modal
	const [selectedTags, setSelectedTags] = useState([]);
	const [openConfirm, setOpenConfirm] = useState(false);

	const [origins, setOrigins] = useState([]);
	const [selectedOrigin, setSelectedOrigin] = useState(null);
	const [status, setStatus] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [productsAndServices, setProductsAndServices] = useState([]);
	const [selectedProductsAndServices, setSelectedProductsAndServices] = useState(null);

	const history = useHistory();

	useEffect(() => {
		if (open != true) return;
		// verificar se o atendente tem acesso as informações de CRM
		const crmAccessVerify = (customerRelationshipAccess == true) ? true : CheckComponentAccess(loggedInUser, 'customerRelationship');

		if (crmAccessVerify == true) {
			(async () => {
				try {

					let { data: whatsappData } = await api.get("/whatsapp/");
					whatsappData.map(obj => { delete obj.Chatbots; return obj; });
					setWhatsapp(JSON.stringify(whatsappData));

					const { data: originData } = await api.get("/customerRelationship/origin/list");
					if (originData.origins && Array.isArray(originData.origins)) setOrigins(originData.origins);
	
					const { data: statusData } = await api.get("/customerRelationship/status/list");
					if (statusData.status && Array.isArray(statusData.status)) setStatus(statusData.status);
	
					const { data: psData } = await api.get("/customerRelationship/productAndService/list");
					if (psData.productsAndServices && Array.isArray(psData.productsAndServices)) setProductsAndServices(psData.productsAndServices);
	
				} catch (err) {
					console.error({ err });
					toastError(err);
				}
			})();
		}

		if (customerRelationshipAccess != true) setCrmAccess(crmAccessVerify);
	}, [open]);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);

				if (data?.origin) setSelectedOrigin(data.origin?.originId || '');
				if (data?.status) setSelectedStatus(data.status?.statusId || '');
				if (data?.productsAndServices && Array.isArray(data.productsAndServices)) setSelectedProductsAndServices(data.productsAndServices.map(ps => ps.productAndServiceId));

				let rg = '';
				let empresa = '';
				let cpf = '';
				let nasc = '';
				let endereco = '';

				let opportunity = "";
				let recurringValue = "0,00";
				let singleValue = "0,00";
				let contractTime = null;
				let contractValue = "0,00";
				let expirationData = null;

				// mapear campos extras dos contatos
				if (data.extraInfo) {

					data.extraInfo.map(item => {
						switch (item.name) {
							case 'rg':
								rg = item.value;
								break;
							case 'cpf':
								cpf = item.value;
								break;
							case 'empresa':
								empresa = item.value;
								break;
							case 'nasc':
								nasc = item.value;
								break;
							case 'endereco':
								endereco = item.value;
								break;
							case 'opportunity':
								opportunity = item.value;
								break;
							case 'recurringValue':
								recurringValue = item.value;
								break;
							case 'singleValue':
								singleValue = item.value;
								break;
							case 'contractTime':
								contractTime = item.value;
								break;
							case 'contractValue':
								contractValue = item.value;
								break;
							case 'expirationData':
								expirationData = item.value;
								break;
						}
					});
				}

				const lgpdDate = data.LGPDAt ? format(new Date(data.LGPDAt), "dd/MM/yyyy HH:mm", { locale: pt }) : '';
		
				var dados = {
					"id": data.id,
					"adminId": data.adminId,
					"name": data.name,
					"number": data.number,
					"email": data.email,
					"rg": rg,
					"empresa": empresa,
					"cpf": cpf,
					"nasc": nasc,
					"endereco": endereco,
					"forAll": data.forAll,
					"createdBy": data.createdBy,
					"connection": data.connection,
					"profilePicUrl": data.profilePicUrl,
					"isGroup": data.isGroup,
					"createdAt": data.createdAt,
					"updatedAt": data.updatedAt,
					"aceiteLGPD": data.acceptLGPD,
					"dataAceiteLGPD": lgpdDate,
					"opportunity": opportunity,
					"recurringValue": recurringValue,
					"singleValue": singleValue,
					"contractTime": contractTime,
					"contractValue": contractValue,
					"expirationData": expirationData,
					"modulesId": data.modulesId,
					"extraInfo": [
						{
							"name": "rg",
							"value": rg
						},
						{

							"name": "empresa",
							"value": empresa,

						},
						{
							"name": "cpf",
							"value": cpf
						},
						{
							"name": "nasc",
							"value": nasc
						},
						{
							"name": "endereco",
							"value": endereco
						},
						{
							"name": "opportunity",
							"value": opportunity
						},
						{
							"name": "recurringValue",
							"value": recurringValue
						},
						{
							"name": "singleValue",
							"value": singleValue
						},
						{
							"name": "contractTime",
							"value": contractTime
						},
						{
							"name": "contractValue",
							"value": contractValue
						},
						{
							"name": "expirationData",
							"value": expirationData
						},
					],
				}

				// liberar filas permitidas e a preencher
				if (whatsapp && data.connection && whatsapp.length > 0) {
					let conn_id = null;
					let connections_tmp = JsonParse(whatsapp);
					for (let cont = 0; cont < connections_tmp.length; cont++) {
						if (connections_tmp[cont]['numberConnection'] == data.connection) {
							conn_id = connections_tmp[cont]['id'];
							break;
						}
					}
				}
        
				setContact(dados);

				// selecionar etiquetas do contato
				if (data.tagsId) {
					try {
						let tagsParse = JsonParse(data.tagsId);
						if (!tagsParse || tagsParse.length < 1) throw new Error('Etiquetas não identificadas');
						setSelectedTags(tagsParse.map(tp => tp.id));
					} catch (err) {
						console.warn(err);
					}
				}
			} catch (err) {
				console.error({ err });
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = (event, reason) => {
		if (reason == 'backdropClick') {
			event.preventDefault();
			return;
		}

		onClose();
		setContact(initialState);
		setSelectedOrigin(null);
		setSelectedStatus(null);
		setSelectedProductsAndServices(null);
		setSelectedTags([]);
		setTabValue(0);
	};

	const handleSaveContact = async values => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, values);
				handleClose();
			} else {

				values.createdBy = loggedInUser.id;  // Pegar o id do usuario que criou este contato
				const { data } = await api.post("/contacts", values);

				if (data?.uuid) history.push(`/tickets/${data.uuid}`);
				if (onSave) onSave(data);

				handleClose();

			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			console.error({ err });
			toastError(err);
		}
	};

	function renderItemConnection(conexao) {
		if (!conexao.numberConnection) {
			return
		}
		return <MenuItem
			value={conexao.numberConnection}
			data-id={conexao.id}
		>
			{conexao.name} - {conexao.numberConnection}
		</MenuItem>
	}

	function RenderSelectConnection(Connections, whatsapp) {
		var rows = [];
		
		for (var conexao of Connections) {
			// Verifica as condições de moduleId
			if ((contact.moduleId == 1 || contact.moduleId == null) && conexao.moduleId == 1) {
				rows.push(renderItemConnection(conexao));
			} else if (contact.moduleId == 2 && conexao.moduleId == 2) {
				rows.push(renderItemConnection(conexao));
			}
		}
		
		return rows;
	}

	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const handleTagChange = useCallback((tagId, isChecked) => {
		setSelectedTags((prev) =>
			isChecked ? [...prev, tagId] : prev.filter(id => id !== tagId)
		);
	}, []);

	const handleDeleteClick = () => {
		setOpenConfirm(true);
	};

	const handleCloseConfirm = () => {
		setOpenConfirm(false);
	};

	const handleConfirmDelete = async values => {
		try {
			if (contactId) {
				// Lógica para excluir a data de aceite LGPD
				await api.put(`/contacts/lgpd/${contactId}`, {});
				//toast.success(i18n.t("contactModal.success"));
			}
			handleClose();
			handleCloseConfirm();
		} catch (err) {
			console.error({ err });
			toastError(err);
		}
	};

	const formatCurrency = (value) => {
		if (!value) return '';
		const numericValue = value.replace(/\D/g, ''); // Remove caracteres não numéricos
		const intValue = parseInt(numericValue, 10);
		let formatted = (intValue / 100).toFixed(2).replace('.', ','); // Divide por 100 e formata

		// Separa a parte inteira e decimal
		let [integerPart, decimalPart] = formatted.split(',');

		// Adiciona o separador de milhar na parte inteira
		integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

		// Junta a parte formatada com a decimal
		return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
	};

	// atualizar função para pegar o valor atual inserido

	const handleContractValue = (form, data) => {

		// resgatar valores para o calculo
		let singleValue = data?.singleValue || form.getFieldProps("singleValue").value;
		singleValue = Number(singleValue.replaceAll('.','').replace(',', '.'));

		let recurringValue = data?.recurringValue || form.getFieldProps("recurringValue").value;
		recurringValue = Number(recurringValue.replaceAll('.','').replace(',', '.'));

		const contractTime = Number(data?.contractTime || form.getFieldProps("contractTime").value) || 0;
		

		// Calcular o valor total do contrato
		const total = String(((recurringValue * contractTime) + singleValue).toFixed(2));
		return formatCurrency(total);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={(event, reason) => handleClose(event, reason)}
				maxWidth="md"
				fullWidth
				scroll="paper"
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions, event) => {
						setTimeout(() => {
							let rg = values.rg;
							let empresa = values.empresa;
							let cpf = values.cpf;
							let nasc = values.nasc;
							let endereco = values.endereco;
							values.number = values?.number && typeof values.number ? values.number.replace(/\D/g, "") : '';

							let opportunity = values?.opportunity || null;
							let recurringValue = values?.recurringValue || null;
							let singleValue = values?.singleValue || null;
							let contractTime = values?.contractTime || null;
							let contractValue = values?.contractValue || null;
							let expirationData = values?.expirationData || null;

							let dados = {
								"id": values.id,
								"adminId": values.adminId,
								"queueId": null,
								"tagsId": JSON.stringify(selectedTags),
								"name": values.name,
								"number": values.number,
								"email": values.email,
								"forAll": values.forAll,
								"connection": values.connection,
								"profilePicUrl": values.profilePicUrl,
								"isGroup": values.isGroup,
								"createdAt": values.createdAt,
								"updatedAt": values.updatedAt,
								"isStartTicket": isStartTicket,
								"status": selectedStatus || null,
								"origin": selectedOrigin || null,
								"productsAndServices": selectedProductsAndServices || null,
								"modulesId": values.modulesId,
								"extraInfo": [
									{
										"name": "rg",
										"value": rg,
										"contactId": values.id
									},
									{

										"name": "empresa",
										"value": empresa,
										"contactId": values.id
									},
									{

										"name": "cpf",
										"value": cpf,
										"contactId": values.id
									},
									{

										"name": "nasc",
										"value": nasc,
										"contactId": values.id
									},
									{

										"name": "endereco",
										"value": endereco,
										"contactId": values.id
									},
									{

										"name": "opportunity",
										"value": opportunity,
										"contactId": values.id
									},
									{

										"name": "recurringValue",
										"value": recurringValue,
										"contactId": values.id
									},
									{

										"name": "singleValue",
										"value": singleValue,
										"contactId": values.id
									},
									{

										"name": "contractTime",
										"value": contractTime,
										"contactId": values.id
									},
									{

										"name": "contractValue",
										"value": contractValue,
										"contactId": values.id
									},
									{

										"name": "expirationData",
										"value": expirationData,
										"contactId": values.id
									}
								],
							}
							handleSaveContact(dados);
							actions.setSubmitting(false);
						}, 400);
					}}
				>

					{({ values, errors, touched, isSubmitting }) => {

						return ((
							<Form style={{ padding: "10px", width: '100%', overflowY: 'hidden' }}>
								<AppBar position="static" className={classes.tab}>
									<Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs" TabIndicatorProps={{ style: { background: '#0b708c' } }}>
										<Tab label={i18n.t("contactModal.form.mainInfo")}
											{...a11yProps(0)}
										/>
										<Tab label={i18n.t("tagForContactsModal.title")} {...a11yProps(1)} style={{ display: tags ? '' : 'none' }} />
										{crmAccess && <Tab label={i18n.t("contactModal.form.opportunity")} {...a11yProps(2)} />}
										{crmAccess && <Tab label={i18n.t("contactModal.form.comments")} {...a11yProps(3)} />}
									</Tabs>
								</AppBar>

								<DialogContent dividers style={{ minWidth: '580px', paddingLeft: '0', paddingRight: '0', height: '60vh', overflowY: 'auto', paddingTop: '0' }}>
									<Box component="div">
										<TabPanel value={tabValue} index={0}>
											<div className="form-row">
												<Field
													as={TextField}
													type="hidden"
													name="adminId"
												/>
												<Field
													as={TextField}
													type="hidden"
													name="modulesId"
												/>
												<div class="form-group col-md-12">
													<label>
														{i18n.t("contacts.form.name")}
														<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
													</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}
														className='form-control '
														placeholder={i18n.t("contactModal.form.name")}
														name="name"
														required
														disabled={contactId && loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
														maxLength={200}
													/>
												</div>

												<div class="form-group col-md-6">
													<label>
														{i18n.t("contacts.form.phoneNumber")}
														<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
													</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}
														required
														disabled={contactId && loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
														className='form-control '
														name="number"
														placeholder="Cod. País + Cod. Estado + número"
														onBlur={(event) => {
															values.number = values.number.replace(/\D/g, "");
															event.target.value = values.number;
														}}
														maxLength={50}
													/>
												</div>

												<div class="form-group col-md-6">
													<label style={{ marginBottom: '11px' }}>
														{i18n.t("contacts.form.connection")}
														<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
													</label>
													<Field
														variant="outlined"
														as={Select}
														required
														disabled={contactId && loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
														name="connection"
														style={{ width: '100%', height: '38px' }}
														MenuProps={{ classes: { paper: classes.select } }}
													>
														{whatsapp && RenderSelectConnection(JsonParse(whatsapp))}
													</Field>
												</div>

												<div class="form-group col-md-8">
													<label>{i18n.t("contacts.form.email")}</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}

														className='form-control '
														name="email"
														placeholder="Email do Contato"
														maxLength={200}
														pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z]{2,})?"
													/>
												</div>
												<FieldArray name="extraInfo">
													{
														<>
															<div class="form-group col-md-4">
																<label>{i18n.t("contacts.form.company")}</label>
																<Field
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="empresa"
																	placeholder="Empresa"
																	type="text"
																	maxLength={100}
																/>
															</div>

															<div class="form-group col-md-8">
																<label>{i18n.t("contacts.form.address")}</label>
																<Field
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="endereco"
																	placeholder="Endereco"
																	type="text"
																	maxLength={200}
																/>
															</div>

															<div class="form-group col-md-4">
																<label>{i18n.t("contacts.form.birth")}</label>
																<Field
																	mask="99/99/9999"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="nasc"
																	placeholder="Data de Nascimento"
																	type="text"
																	maxLength={50}
																/>
															</div>

															<div class="form-group col-md-8">
																<label>{i18n.t("contacts.form.rg")}</label>
																<Field
																	mask="99.999.999-9"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="rg"
																	placeholder="RG"
																	type="text"
																	maxLength={50}
																/>
															</div>

															<div class="form-group col-md-4">
																<label>{i18n.t("contacts.form.cpf")}</label>
																<Field
																	mask="999.999.999-99"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="cpf"
																	placeholder="CPF"
																	type="text"
																	maxLength={15}
																/>
															</div>

															{contactId && (
																<div className="form-group col-md-6">
																	<label>{i18n.t("contacts.form.lgpdAt")}</label>
																	<div style={{ display: 'flex', alignItems: 'center' }}>
																		<Field
																			style={{
																				borderColor: '#c4c4c4',
																				borderRadius: '3px',
																				margin: '5px 0',
																				flex: 1 // Permite que o input ocupe o espaço disponível
																			}}
																			className='form-control'
																			name="dataAceiteLGPD"
																			placeholder="Data aceite LGPD"
																			type="text"
																			disabled={true}
																		/>
																		{contact.dataAceiteLGPD && (
																			<Tooltip title="Excluir aceite LGPD">
																				<IconButton onClick={handleDeleteClick} style={{ marginLeft: '5px' }}>
																					<DeleteIcon />
																				</IconButton>
																			</Tooltip>
																		)}
																	</div>
																</div>
															)}

															<div class="form-group col-md-12">
																<label style={{ marginTop: '40px' }}>{i18n.t("contacts.form.forAllUsers")}</label>
																<Field
																	as={Switch}
																	color="primary"
																	name="forAll"
																	checked={values.forAll}
																	disabled={loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
																/>
															</div>
														</>
													}
												</FieldArray>
											</div>
										</TabPanel>
										<TabPanel value={tabValue} index={1}>
											<TagCheckList tags={tags} selectedTags={selectedTags} onChange={handleTagChange} />
										</TabPanel>
										<TabPanel value={tabValue} index={2}>
											<div className="form-row">
												<div class="form-group col-md-12">
													<SingleSelect
														title={i18n.t("contacts.form.status")}
														displayText={i18n.t("contacts.form.selectStatus")}
														name="opportunityStatus"
														key="opportunityStatus"
														disabled={false}
														options={status.map((stat) => { return { key: stat.id, value: stat.name, disabled: !stat.status } })}
														onSelected={(opt) => {
															setSelectedStatus(opt)
														}}
														selected={selectedStatus}
													/>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<SingleSelect
														title={i18n.t("contacts.form.origin")}
														displayText={i18n.t("contacts.form.selectOrigin")}
														name="opportunityOrigin"
														key="opportunityOrigin"
														disabled={false}
														options={origins.map((origin) => { return { key: origin.id, value: origin.name, disabled: !origin.status } })}
														onSelected={(opt) => {
															setSelectedOrigin(opt)
														}}
														selected={selectedOrigin}
													/>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<MultiSelect
														title={i18n.t("contacts.form.productsAndServices")}
														displayText={i18n.t("contacts.form.selectProductsAndServices")}
														name="opportunityProductsAndServices"
														key="opportunityProductsAndServices"
														disabled={false}
														options={productsAndServices.map((ps) => { return { key: ps.id, value: ps.name, disabled: !ps.status } })}
														onSelected={(options) => {
															setSelectedProductsAndServices(options);
														}}
														selecteds={selectedProductsAndServices}
													/>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<label>{i18n.t("contactModal.form.opportunity")}</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}
														name="opportunity"
														className='form-control'
														placeholder={i18n.t("contactModal.form.opportunity")}
														inputProps={{ maxLength: 200 }}
													/>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<label>{i18n.t("contactModal.form.opportunityRecurringValue")}</label>
													<Field name="recurringValue">
														{({ field, form }) => {
															const handleChange = (e) => {
																const inputValue = e.target.value;
																// Formatar o valor como moeda
																const formattedValue = formatCurrency(inputValue);
																form.setFieldValue("recurringValue", formattedValue);

																// calcular e atualizar o valor do contrato
																form.setFieldValue("contractValue", handleContractValue(form, { recurringValue: formattedValue }));
															};

															return (
																<InputMask
																	{...field}
																	value={field.value || ""}
																	onChange={handleChange}
																	mask=""
																	placeholder="0,00"
																	className="form-control"
																	style={{
																		borderColor: "#c4c4c4",
																		borderRadius: "3px",
																	}}
																	maxLength={10}
																/>
															);
														}}
													</Field>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<label>{i18n.t("contactModal.form.opportunitySingleValue")}</label>
													<Field name="singleValue">
														{({ field, form }) => {
															const handleChange = (e) => {
																const formattedValue = formatCurrency(e.target.value);
																form.setFieldValue("singleValue", formattedValue);

																// calcular e atualizar o valor do contrato
																form.setFieldValue("contractValue", handleContractValue(form, { singleValue: formattedValue }));
															};

															return (
																<InputMask
																	{...field}
																	value={field.value || ''}
																	onChange={handleChange}
																	mask=""
																	placeholder="0,00"
																	className="form-control"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	maxLength="10"
																/>
															);
														}}
													</Field>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<label>{i18n.t("contactModal.form.opportunityContractTime")}</label>
													<Field name="contractTime">
													{({ field, form }) => {
															const handleChange = (e) => {
																const inputValue = e.target.value;
																form.setFieldValue("contractTime", inputValue);

																// calcular e atualizar o valor do contrato
																form.setFieldValue("contractValue", handleContractValue(form, { contractTime: inputValue }));
															};

															return (
																<InputMask
																	{...field}
																	value={field.value || ""}
																	onChange={handleChange}
																	placeholder={i18n.t("contactModal.form.opportunityContractTime")}
																	className="form-control"
																	style={{
																		borderColor: "#c4c4c4",
																		borderRadius: "3px",
																	}}
																	type="number"
																	min={1}
																	max={999}
																/>
															);
														}}
													</Field>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<label>{i18n.t("contactModal.form.opportunityContractValue")}</label>
													<Field name="contractValue">
														{({ field, form }) => {
															const handleChange = (e) => {
																const formattedValue = formatCurrency(e.target.value);
																form.setFieldValue("contractValue", formattedValue);
															};

															return (
																<InputMask
																	{...field}
																	disabled={true}
																	value={field.value || ''}
																	onChange={handleChange}
																	mask=""
																	placeholder="0,00"
																	className="form-control"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	maxLength="10"
																/>
															);
														}}
													</Field>
												</div>
											</div>
											<div className="form-row">
												<div class="form-group col-md-12">
													<label>{i18n.t("contactModal.form.opportunityExpirationData")}</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}
														name="expirationData"
														className='form-control '
														placeholder={i18n.t("contactModal.form.opportunityExpirationData")}
														maxLength={200}
														type="number"
														min={1}
														max={31}
													/>
												</div>
											</div>
										</TabPanel>
										<TabPanel value={tabValue} index={3}>
											<CommentTab contactId={contactId} />
										</TabPanel>
									</Box>
								</DialogContent>

								<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										{i18n.t("contactModal.buttons.cancel")}
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										onClick={() => setIsStartTicket(false)}
										variant="contained"
										className={classes.btnWrapper}
									>
										{contactId
											? `${i18n.t("contactModal.buttons.okEdit")}`
											: `${i18n.t("contactModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
									{(!contactId && byContactPage) && <Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										id="startTicket"
										onClick={() => setIsStartTicket(true)}
										variant="contained"
										className={classes.btnWrapper}
									>
										{i18n.t("contacts.buttons.startConversation")}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>}
								</DialogActions>
							</Form>
						))
					}}
				</Formik>
			</Dialog>
			{/* Modal de Confirmação */}
			<Dialog open={openConfirm} onClose={handleCloseConfirm}>
				<DialogTitle>Confirmar Exclusão</DialogTitle>
				<DialogContent>
					<p>
						Deseja realmente excluir a data de aceite LGPD do cliente?
						<br />
						Ao excluir, caso o cliente entre em contato futuramente, o fluxo LGPD será reapresentado para novo aceite.
					</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirm} color="secondary">
						Cancelar
					</Button>
					<Button onClick={handleConfirmDelete} color="primary">
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	);
};

export default ContactModal;
