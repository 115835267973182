import { useContext, useEffect, useReducer, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import socket from "../useSocket";

const reducer = (state, action) => {
	switch (action.type) {
		case "LOAD_WHATSAPPS":
			return [...action.payload];
		case "UPDATE_WHATSAPPS":
			const updatedWhatsApp = action.payload;
			const index = state.findIndex((s) => s.id == updatedWhatsApp.id);
			if (index !== -1) {
				const newState = [...state];
				newState[index] = updatedWhatsApp;
				return newState;
			}
			return [updatedWhatsApp, ...state];
		case "DELETE_WHATSAPPS":
			return state.filter((s) => s.id != action.payload);
		case "RESET":
			return [];
		default:
			return state;
	}
};

const useWhatsApps = () => {
	const [whatsApps, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		let isActive = true;
		const fetchWhatsApps = async () => {
			setLoading(true);
			try {

				const { data } = await api.get("/whatsapp/");
				if (isActive) {
					dispatch({ type: "LOAD_WHATSAPPS", payload: data });
				}
			} catch (err) {
				if (isActive) toastError(err);
			} finally {
				if (isActive) setLoading(false);
			}
		};
		fetchWhatsApps();

		return () => {
			isActive = false; // Cancelar a atualização em caso de desmontagem.
		};
	}, []);

	useEffect(() => {
		const eventName = `whatsapp-${user?.adminId}`;
		const handleSocketEvent = (data) => {
			switch (data.action) {
				case "create":
				case "update":
					dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
					break;
				case "delete":
					dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
					break;
				default:
					break;
			}
		};

		socket.on(eventName, handleSocketEvent);

		return () => {
			socket.off(eventName, handleSocketEvent); // Limpeza do evento.
		};
	}, [user?.adminId]);

	return { whatsApps, loading };
};

export default useWhatsApps;
