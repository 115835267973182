import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import EditMessageModal from "../EditMessageModal";
import { AuthContext } from "../../context/Auth/AuthContext";

function checkElapsedTime(creationDate) {
  const creationDateObj = new Date(creationDate);
  const now = new Date();
  const timeDifferenceInMilliseconds = now - creationDateObj;
  const elapsedTimeInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
  if (elapsedTimeInMinutes > 15) {
    return false;
  } else {
    return true;
  }
}

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(null);
  const { user } = useContext(AuthContext);

  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  const SubmitEditMessage = async (body) => {
    setLoading(true);
    try {
      await api.post(`/messages/edit/${message.id}`, { body });
      setEditOpen(false);
    } catch (err) {
      console.error({err});
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };


  const handleEditMessage = () => {
    setEditMessage(message);
    handleClose();
    setEditOpen(true);
  };

  return (
    <>
      <EditMessageModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        message={editMessage}
        loading={loading}
        open={editOpen}
        onClose={setEditOpen}
        onSave={SubmitEditMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </EditMessageModal>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {/* Rendering the edit menu item only if the message is older than 15 minutes */}
        {
          message.fromMe == true && (checkElapsedTime(message.createdAt) || message?.annotation == true) && 
          (
            <MenuItem
              key="edit"
              onClick={handleEditMessage}
              disabled={(message?.annotation != true && message.ack > 1) || (message?.annotation == true && message?.sentBy?.id == user?.id && !message?.mediaName) ? false : true}
            >
              {i18n.t("messageOptionsMenu.edit")}
            </MenuItem>
          )
        }
        {message.fromMe == true  && (
          <MenuItem 
            onClick={handleOpenConfirmationModal}
            disabled={message?.annotation != true || (message?.annotation == true && message?.sentBy?.id == user?.id) ? false : true}
          >
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
        {message?.annotation != true && (
        <MenuItem onClick={handleReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MessageOptionsMenu;
