import { Container, Grid, Typography, Button } from "@material-ui/core"; // Corrigido para Material-UI v4
import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { i18n } from "../../../translate/i18n";
import { KTSVG } from "../../../../_metronic/helpers";
import WhatsappTemplateTable from "../../../components/WhatsApp/Template/table";
import StoreModal from "../../../components/WhatsApp/Template/storeModal";

// Estilos usando makeStyles para manter o código organizado
const useStyles = makeStyles((theme) => ({
    container: {
        margin: "20px 10px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "10px",
        width: "auto",
        maxWidth: "100%",
    },
    button: {
        padding: "7px",
        float: "right",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "& svg": {
            color: theme.palette.primary.contrastText,
            marginRight: '5px'
        },
    },
}));

const WhatsappTemplate = () => {
    const classes = useStyles(); // Hook para acessar as classes de estilo

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <Container className={classes.container}>
            <Grid container spacing={5}>
                <Grid item sm={12} xs={12}>
                    <Typography variant="h6">{i18n.t("connections.whatsapp.template.title")}</Typography>
                    <Button
                        size="small"
                        variant="contained"
                        className={classes.button}
                        onClick={handleModalOpen}
                    >
                        <KTSVG
                            path="media/icons/duotune/arrows/arr075.svg"
                            className="svg-icon-3"
                        />
                        {i18n.t("connections.whatsapp.template.buttons.add")}
                    </Button>
                </Grid>
            </Grid>

            <WhatsappTemplateTable style={{ boxShadow: 'none', textOverflow: 'ellipsis', marginTop: '20px' }} />

            <StoreModal 
                open={modalOpen}
                id={undefined}
                onClose={handleModalClose}
                templateData={undefined}
            />
        </Container>
    );
};

export default WhatsappTemplate;
