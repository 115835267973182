const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        title: "Welcome",
        updatedAt: "updated at: ",
        tickets: "Tickets",
        chart: {
          title: "Total: ",
          legends: {
            open: "Open",
            pending: "Pending",
            closed: "Closed"
          }
        },
        filters: {
          default: "select a filter",
          updatedToday: "updated today",
          unanswered: "unread"
        },
        cards: {
          inAttendance: "INBOX",
          waiting: "TICKETS WAITING FOR RESPONSE",
          activeAttendants: "Online Agents",
          finalized: "TICKETS CLOSED (TODAY)",
          ticketWithoutAttendants: "TICKETS W/ OFFLINE AGENT",
          totalReceivedMessages: "MESSAGES RECEIVED (TODAY)",
          totalSentMessages: "MESSAGES SENT (TODAY)",
          averageServiceTime: "SERVICE TIME AVERAGE",
          chatbotTickets: "TICKETS IN CHATBOT FLOW",
          status: "Status (Current)",
          activeTickets: "ACTIVE CAMPAIGNS",
          passiveTickets: "TICKETS OF THE DAY",
          unread: "UNREAD MESSAGES",
        }
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        form: {
          name: "name",
          message: "Greeting message",
          queue: "Select linked queues",
          getContactOnHold: "Pull contact that is on hold when starting a service"
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
          loginFacebook: "Login with FaceBook",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          lastDisconnection: "Last Disconnection",
          default: "Default",
          actions: "Actions",
          session: "Session",
          option: {
            moduleTitle: "Service Channel",
            title: "Connection Type",
            receptive: "Treatment",
            massive: "Massive Messages"
          },
          number: "Number"
        },
        whatsapp: {
          template: {
            title: "Whatsapp Templates",
            table: {
              id: "Id",
              name: "Name",
              status: "Status",
              qualityRating: "Quality Rating",
              lastUpdated: "Last Updated",
              actions: "Options",
              notFound: "No templates were found",
              filters: {
                quantity: "Quantity"
              }
            },
            modal: {
              storeTitle: "Create Template",
              editTitle: "Edit Template",
              name: "Name",
              language: "Language",
              selectLanaguage: "Select a Language",
              connection: "Connection",
              selectConnection: "Select a Connection",
              email: "Email",
              emailPlaceholder: "Press enter to add one or more emails.",
              category: "Category",
              selectCategory: "Select a Category",
              categoryOptions: {
                authentication: "Authentication",
                marketing: "Marketing",
                utility: "Utility"
              },
              messageType: {
                title: "Message Type",
                subtitle: "You can choose between the two types of messages below. The maximum character limit way vary depending on the type chosen.",
                simple: {
                  title: "Simple message",
                  body: "Hello, your message here.",
                  max: "Message can contain up to 1024 characters."
                },
                customMessageSelected: {
                  title: "Message with header, media, footer and buttons",
                  header: "Message header",
                  body: "Hello, your message here.",
                  footer: "Message footer",
                  max: "Message can contain up to 1024 characters.",
                  buttonLink: "Button link"
                },
                otpMessageSelected: {
                  title: "Message with an OTP button",
                  body: "797011 is your verification code. For your security, do not share this code.",
                  expires: "This code expires in 5 minutes.",
                  copy: "Copy code"
                },
              },
              authentication: "The use of URLs, media and emojis in the content, nor in the parameter of this model, is not allowed. The parameter must respect the limit of 25 characters. It will be mandatory to use 1 button in the layout.",
              marketing: "For use in campaigns, promotions and offers; welcome and farewell messages; updates, invitations or recommendations; or requests to respond or complete a new transaction.",
              utility: "Words such as: Offer, promotion, bonus, discount, survey should not be used, so that the template is not considered a Marketing contact. Welcoming contexts, farewells, invitations or recommendations should also be avoided.",
              configuration: "Settings",
              templateContent: "Template Content",
              required: "is required.",
              invalid: "is invalid",
              typeOfOtp: {
                title: "Type of OTP",
                select: "Select a type",
                copyCode: "Coupon (Copy code)",
                oneTap: "Autofill (One Tap)"
              },
              buttons: {
                save: "Save",
                cancel: "Cancel",
                next: "Next",
                previous: "Previous",
                add: "Add"
              },
              securityAdvisory: "Add security advisory?",
              securityAdvisoryMessage: " For your security, do not share this code.",
              addCodeExpirationTime: "Add code expiration time?",
              otpMessage: "is your verification code.",
              expirationTimeMessage: "This code expires in {minutes} minutes.",
              buttonText: "Button text",
              packageName: "Package name",
              applicationSignatureHash: "Application signature hash",
              messageBody: "Message body",
              addFooter: {
                title: "Footer (Your message can contain a footer as text).",
                without: "Without footer",
                text: "text"
              },
              footerText: "Footer text",
              headerOption: {
                title: "Header (Your message can contain a header with highlighted text, image, document or video).",
                without: "Without header",
                text: "Text",
                image: "Image",
                document: "Document",
                video: "Video"
              },
              headerText: "Header text",
              dragText: "Drag a file here or click the button below to select.",
              selectFile: "Select a file",
              invalidFormatFile: "Invalid file was selected. Accepted formats are:",
              buttonsOption: {
                title: "Button (Your message can contain buttons of different types).",
                without: "Without buttons",
                text: "With buttons",
                type: "Type",
                typeText: "Enter your text",
                select: "Select a type",
                url: "URL",
                phoneNumber: "Phone number",
                quickReply: "Quick reply",
                optOut: "Unsubscribe"
              },
            },
            buttons: {
              add: "Create New Template"
            }
          }
        }
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
          option: {
            title: "Connection type",
            receptive: "Treatment",
            massive: "Massive Messages",
            receptivePhrase: "Treatment: Exclusive for consultations.",
            massivePhrase: "Mass Messages: For customer service, launching campaigns and sending broadcast lists."
          }
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      schedules: {
        title: "Schedules",
        table: {
          columns: {
            message: "Message",
            date: "Sending Date",
            recurrence: "Recurrence",
            status: "Status",
            actions: "Actions"
          },
          buttons: {
            send: "Send immediately",
            edit: "Edit schedule",
            delete: "Delete schedule",
          },
          status: {
            await: "Awaiting",
            started: "Started",
            finished: "Finished",
            error: "Error",
          },
          noData: "No Schedules"
        },
        noData: "No information was found.",
        scheduleBy: "Schedule By:"
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        filters: {
          searchPlaceholder: "Search...",
          searchTooltip: "Search by the contact's name, number, company, email or CPF.",
          connections: "All connections",
          users: "All users",
          tags: "Filter tags",
        },
        form: {
          name: "Name",
          phoneNumber: "Phone Number",
          connection: "Connection",
          queue: "Queue",
          email: "Email",
          company: "Company",
          address: "Address",
          birth: "Date of Birth",
          rg: "RG",
          cpf: "CPF",
          acceptLgpd: "LGPD Acceptance",
          lgpdAt: "LGPD Acceptance Date",
          forAllUsers: "All Users?",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
          startConversation: "Start Conversation",
          newContact: "New contact",
          importContacts: "Import contacts"
        },
        table: {
          channel: "Canal",
          name: "Name",
          origem: "Origem",
          email: "Email",
          company: "Company",
          createdBy: "Created By",
          connection: "Connection",
          actions: "Actions",
        },
        titles: {
          startConversation: "Start Conversation",
          edit: "Edit",
          delete: "Delete"
        },
        errors: {
          queueNotFound: "Select a queue."
        }
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      tagModal: {
        title: {
          add: "Add Tag",
          edit: "Edit Tag",
        },
        form: {
          name: "Name",
          color: "Color",
          status: "Status",
          keywords: "Keywords"
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Tag saved successfully.",
        error: "Error while saving tag.",
      },
      tagForContactsModal: {
        title: "Link Tags",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
        success: "Links saved successfully.",
        error: "Error while saving link.",
        removePreviousTags: "Remove previous tag(s).",
      },
      openingHourModal: {
        title: {
          add: "Add Opening Hour",
          edit: "Edit Opening Hour"
        },
        form: {
          name: "Name",
          weekdays: "Weekdays",
          hourStartName: "Start Time",
          hourEndName: "End Time",
          holiday: "Avoid Holidays",
          status: "Status",
          message: "Out of Hours Message",
          tabs: {
            holidays: "Don't work on Holidays",
            linkQueues: "Link Queues"
          },
          weekDays: {
            sun: "Sunday",
            mon: "Monday",
            tue: "Tuesday",
            wed: "Wednesday",
            thu: "Thursday",
            fry: "Friday",
            sat: "Saturday"
          }
        },
        invalidMessages: {
          name: {
            required: "Enter the name.",
            max: "The name field exceeded the maximum character limit.",
            invalid: "This name has already been used for another opening hour."
          },
          message: {
            required: "Enter a return message for your clients.",
            max: "The message field exceeded the maximum character limit."
          },
          weekDays: {
            required: "Enter the weekdays when you will be available for service."
          },
          status: {
            required: "Error validating status."
          },
          deleted: "Opening hour deleted successfully."
        },
        successMessages: {
          save: "Opening hour saved successfully."
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          selectAllHolidays: "Select All Holidays",
          selectAllQueues: "Select All Queues",
        },
        success: "Opening hour saved successfully.",
        error: "Error saving opening hour."
      },
      quickConcludeMessage: {
        title: {
          add: "Add finalization message",
          edit: "Add finalization message",
        },
        form: {
          shortcut: "Shortcut",
          message: "Finalization message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          new: "Add finalization message",
        },
        list: {
          title: "Finalization messages",
          shortcut: "Shortcut",
          message: "Finalization messages",
          actions: "Actions",
        },
        errors: {
          alreadyExist: "Finalization message already exist.",
          companyIdNotFound: "Company id not found.",
          shortcutNotFound: "Shortcut not found.",
          IdNotFound: "Finalization message id not found.",
        },
        success: "Finalization message saved succesfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        errors: {
          colorNotFound: "Select a queue color."
        }
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
        loadMore: "load more"
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          pending: { title: "Waiting for return" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
          managedBox: { title: "Managed box" },
          ura: { title: "URA" },
          chatbot: { title: "Chatbot" }
        },
        search: {
          placeholder: "Search tickets or messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        buttons: {
          accept: "Accept",
        },
      },
      ticketsSearch: {
        tooltip: "Search settings",
        title: "Filter options",
        channel: "Channel",
        queues: "Service Queue(s)",
        tags: "Tag(s)",
        noTags: "No Tag(s)",
        selectAllTags: "All Tag(s)",
        content: {
          title: "Content",
          contact: "Contact",
          message: "Message"
        }
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Start",
          cancel: "Cancel",
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      concludeMessage: {
        title: "Finalization messages",
        table: {
          shortcut: "Shortcut",
          message: "Finalization messages",
          actions: "Actions",
        },
        buttons: {
          add: "Add finalization message",
        },
        toasts: {
          deleted: "Finalization message successfully deleted.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this finalization message?",
          deleteMessage: "This action cannot be reversed.",
        },
      },
      quickAnswers: {
        title: "Quick Answers",
        filterDefault: "All users",
        table: {
          shortcut: "Shortcut",
          message: "Quick answer",
          creator: "Creator",
          actions: "Actions",
        },
        buttons: {
          add: "Add quick answer",
          file: "FILE",
          allUsers: "show to all users"
        },
        toasts: {
          deleted: "Quick answer deleted successfully.",
          copied: "Message copied successfully.",
          cannotCopied: "Files cannot be copied.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick answer?",
          deleteMessage: "This action cannot be undone.",
        },
        contactMessenger: {
          send: "Send quick answer",
          add: "Add quick answer",
          userShortcuts: "User shortcuts",
          systemShortcuts: "System shortcuts",
        },
        errors: {
          alreadyExist: "Quick answer already exist.",
          companyIdNotFound: "Company id not found.",
          shortcutNotFound: "Shortcut not found.",
          IdNotFound: "Quick answer id not found.",
          messageOrFileNotFound: "Select a file or enter a message.",
        },
      },
      tags: {
        title: "Tags",
        filterDefault: "All users",
        table: {
          name: "Name",
          color: "Color",
          status: "Status",
          updatedBy: "Last edited by",
          actions: "Actions",
        },
        buttons: {
          add: "Add tag",
        },
        toasts: {
          deleted: "Tag deleted successfully.",
          saved: "Tag saved successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this tag?",
          deleteMessage: "This action cannot be undone.",
        },
        errors: {
          alreadyExist: "Tag already exists.",
          companyIdNotFound: "Company ID not found.",
          IdNotFound: "Tag ID not identified.",
        }
      },
      openingHours: {
        title: "Opening Hours",
        filterDefault: "All users",
        table: {
          name: "Name",
          start: "Start Time",
          end: "End Time",
          status: "Status",
          updatedBy: "Last Edited By",
          actions: "Actions",
        },
        buttons: {
          add: "Add Hours",
        },
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this schedule?",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        search: "Search...",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
        states: {
          online: "Online",
          out: "Out",
          offline: "Offline"
        }
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
            conclude: "Conclude",
            autoMessage: "Quick messages",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Type a message",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        placeholderConnection: "The connection is unavailable to interact.",
        signMessage: "Sign",
        annotation: 'Annotation'
      },
      contactDrawer: {
        header: "Contact details",
        headerTicket: "Open message",
        headerTransfer: "Transfer message",
        headerConclude: "Finalization messages",
        headerFlashMessage: "Quick answers",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        transferToAwait: "Move to waiting",
        linkTags: "Link tags",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        edit: "Edit",
        delete: "Delete",
        reply: "Reply",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },

      ticketsByTags: {
        title: "Tickets by Tags Report",
        updated: "Updated on: ",
        ticketsByTags: "Tickets by Tags",
        ticketsByTagsTotal: "Total: ",
        tabs: {
          open: "Inbox",
          waitingForReturn: "Waiting for Return",
          closed: "Closed"
        },
        filters: {
          title: "Filters",
          startDate: "Start Date",
          endDate: "End Date",
          tags: "Tags",
          selectTags: "Select tags",
          connections: "Connections",
          selectConnections: "Select connections",
          queues: "Queues",
          selectQueues: "Select queues",
          selectAllTags: "Select All Tag(s)",
          selectWithoutTags: "Select Without Tag(s)",
          selectAllConnections: "Select All Connections",
          selectAllQueues: "Select All Queues"
        },
        excelExport: {
          id: "Identificador",
          status: "Status",
          connectionName: "nomeConexão",
          connection: "numeroConexão",
          lastMessage: "ultimaMensagem",
          lastMessageDate: "dataUltimaMensagem",
          tag: "Etiqueta",
          queueName: "Fila",
          finishedAt: "dataFinalização",
          finishedBy: "finalizadoPor",
          finishedBy: "finalizadoPor",
          attendant: "Atendente",
          contactName: "contato",
          contactNumber: "numeroContato",
          conclusionMessage: "Finalização",
          lastReason: "motivoFinalização",
          isGroup: "Grupo",
          isChatbot: "Chatbot",
          translate: {
            isGroup: {
              yes: "sim",
              no: "não"
            },
            isChatbot: {
              yes: "sim",
              no: "não"
            },
            status: {
              open: "Inbox",
              pending: "Pending",
              closed: "Finished"
            }
          }
        },
        buttons: {
          apply: "Apply",
          close: "Close",
          export: "Exportar"
        }
      },
      relatoryGeneralCampaigns: {
        title: "Campaign Report",
        updatedAt: "Updated at:",
        campaigns: {
          title: "Campaigns",
          total: "Total campaigns:",
          statusType: {
            started: "In Progress",
            paused: "Paused",
            finished: "Finished",
            waiting: "Waiting to Start",
          },
        },
        messages: {
          title: "Messages",
          subtitle: "Total messages sent per day.",
          statusType: {
            answered: "Answered",
            open: "Awaiting Sending",
            sent: "Sent",
            error: "Error Sending",
            blacklist: 'Blacklist'
          },
        },
        history: {
          title: "History",
          table: {
            name: "Name",
            begin: "Start",
            end: "End",
            days: "Days of the Week",
            holiday: "Holidays",
            status: "Status",
            createdAt: "Creation Date",
            moveTo: "Move to",
            totQty: "Total Messages",
            sentQty: "Sent Messages",
            notSentQty: "Unsent Messages",
            errorQty: "Messages with Errors",
            numberConnection: "Connection",
            queue: "Queue",
            action: "Actions",
            statusType: {
              started: "In Progress",
              paused: "Paused",
              finished: "Finished",
              waiting: "Waiting to Start",
            },
            relatoryTitle: "Campaign_Report",
          },
          buttons: {
            relatory: "Report",
            message: "View Message",
            export: "Export",
            close: "Close",
          },
        },
        filters: {
          title: "Filters",
          startDate: "Start Date",
          endDate: "End Date",
          connections: "Connections",
          selectConnections: "Select Connections",
          queues: "Queues",
          selectQueues: "Select Queues",
        },
        buttons: {
          apply: "Apply",
          close: "Close",
        },
        errors: {
          invalid_interval_date: "Maximum interval is 1 month.",
        },
      },
      ack: {
        error: 'Error to sent',
        pending: 'Pending',
        sent: 'Sent',
        serverAck: 'Delivered',
        read: "Read",
        broadcast: "By broadcast",
        annotation: "Annotation",
        scheduling: "Scheduling",
        schedulingBy: "Scheduling By:",
        isApi: "Api"
      },
      buttons: {
        edit: "Edit",
        delete: "Delete",
        save: "Save",
        copy: "Copy",
        add: "Add",
        info: "Info",
        close: "Close"
      },
      relatoryTicket: {
        status: {
          open: "Inbox",
          closed: "Resolved",
          pending: "Waiting for Return"
        }
      },
      // tagForContactsModal:{
      //   removePreviousTags: "Remove Previous Tags",
      // },
      weekDays: {
        sun: "Sunday",
        mon: "Monday",
        tue: "Tuesday",
        wed: "Wednesday",
        thu: "Thursday",
        fri: "Friday",
        sat: "Saturday",
        today: "Today",
        yesterday: "Yesterday"
      },
      databaseFound: {
        title: "Database not found",
        message: "There was a problem identifying your company's database, contact the administrator.",
        button: "Home"
      },
      pageFound: {
        title: "Page not found",
        message: "The page isn't exist.",
        button: "Home"
      },
      importContactsModal: {
        title: "Send the file with contacts to add to the system",
        loadMessage: "Validating sent contacts...",
        errorMessage: {
          start: "Errors were found with",
          end: "contacts in the system.",
          warning: "Correct these contacts by downloading the file by clicking the button below.",
          button: "Download to correct"
        },
        uploadButton: "Send the file",
        downloadButton: "Download Model",
        importButton: "Import Contacts",
        uploadingMessage: "Please wait, uploading file...",
        valid: "Valid:",
        invalid: "Invalid:",
        duplicated: "Duplicates:",
        tabs: {
          main: "Main Data",
          tags: "Tags"
        },
        form: {
          whatsapp: "Linked Whatsapp",
          queue: "Queue",
          forAll: "For all users?"
        }
      },
      integrations: {
        title: "Integrations",
        subtitle: "Configure interactions between the service platform and other systems.",
        api: {
          title: "API",
          table: {
            title: "title",
            name: "name",
            status: "status",
            method: "method",
            actions: "actions",
            createdAt: "created at",
            updatedAt: "updated at"
          },
          form: {
            create: "register api",
            update: "update api",
            info: "api information",
            title: "title",
            name: "name",
            method: "method",
            connection: "connection",
            sendContactAnswer: "resend contact answer",
            url: "url",
            headers: "headers",
            answerContact: "send a default answer when the user replies to the submission?",
            answerContactPlaceholder: "write your message...",
            validations: {
              titleRequired: "Input a title",
              titleMin: "The title is too short",
              nameRequired: "Select a name",
              nameMin: "The selected name is too short",
              methodRequired: "Select a method",
              methodMin: "The selected method is too short",
              connectionRequired: "Select a connection",
              urlRequired: "Enter a URL",
              urlMin: "The provided URL is too short",
              success: "Saved successfully",
              deleted: "Deleted successfully"
            }
          },
          buttons: {
            add: "add api",
            cancel: "cancel",
            save: "save"
          }
        },        
        webhook: {
          title: "WEBHOOK",
          table: {
            name: "name",
            url: "url",
            status: "status",
            method: "method",
            edit: "edit"
          },
          form: {
            create: "register webhook",
            update: "update webhook",
            name: "name",
            method: "method",
            url: "url",
            auth: {
              option: "authentication",
              user: "user",
              password: "password"
            },
            queryString: "querystring",
            json: "json",
            capture: "captures",
            headers: "headers",
            fields: "fields",
            validations: {
              nameRequired: "Select a name",
              nameMin: "The selected name is too short",
              methodRequired: "Select a method",
              methodMin: "The selected method is too short",
              urlRequired: "Enter a URL",
              urlMin: "The provided URL is too short",
              authOptionRequired: "Select an authentication",
              authOptionMin: "The provided authentication is too short",
              authUserRequired: "Enter an authentication user",
              authUserMin: "Authentication user is too short",
              authPasswordRequired: "Enter an authentication password",
              authPasswordMin: "Authentication password is too short",
              success: "Saved successfully",
              deleted: "Deleted successfully"
            },
            customFields: {
              key: {
                title: "key",
                required: "Enter a key",
                min: "The key value is too short",
                added: "key already added"
              },
              value: {
                title: "value",
                required: "Enter a value",
                min: "The value is too short"
              },
              message: {
                title: "Return position",
                required: "Enter a return position",
              },
            },
          },
          buttons: {
            add: "add webhook",
            cancel: "cancel",
            save: "save"
          }
        },
      },
      batchReopen: {
        title: "Open Ticket",
        whatsapp: "Select a connection",
        queue: "Select a queue",
        buttons: {
          cancel: "Cancel",
          confirm: "Confirm"
        }
      },
      menu: {
        list: {
          ticket: "Support",
          contact: "Contact",
          dashboard: "Dashboard",
          report: {
            title: "Report",
            ticketsRelatory: "Support",
            tagsRelatory: "Support by Tag",
            generalCampaignsRelatory: "Campaign"
          },
          campaign: "Campaign",
          admin: {
            title: "Cconfiguration",
            connection: "Connection",
            queue: "Queue",
            quickAnswer: "Quick Message",
            conclusionMessage: "Completion",
            openingHour: "Opening Hours",
            tag: "Tag",
            user: "User",
            integration: "Integration",
            chatbot: "Chatbot",
            customerRelationship: "CRM"
          },
          adminSuper: {
            company: "Company",
            component: "Components"
          }
        },
        profile: {
          changePassword: "change password",
          changeProfile: "change profile",
          logout: "logout"
        }
      },
      changePassword: {
        success: "Password successfully changed.",
        modal: {
          title: "Change Password",
          password: "Password",
          newPassword: "New Password",
          confirmPassword: "Confirm Password",
          newPasswordAlerts: {
            title: "Password security level - The new password must contain: ",
            qty: "8 Characters;",
            uppercase: "1 Uppercase Letter;",
            lowercase: "1 Lowercase Letter;",
            special: "1 Special Character;",
            number: "1 Numeric Character."
          }
        },
        buttons: {
          add: "Add",
          cancel: "Cancel"
        }
      },
      component: {
        title: "Components",
        table: {
          name: "Name",
          description: "Description",
          status: "Status",
          createdAt: "Created At:"
        },
        byCompanyModal: {
          title: "Manage access to system components",
          cancel: "Cancel",
          save: "Save"
        },
        messages: {
          updated: "Component updated successfully.",
          updatedLinks: "Components updated successfully."
        }
      },
      forms: {
        min: "is too short.",
        max: "is too long.",
        invalid: "is invalid.",
        required: "is required."
      },      
      groupPrefix: "GROUP | ",
      selectAll: "Select All",
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "There must be at least one default WhatsApp.",
        ERR_NO_DEF_WAPP_FOUND: "No default WhatsApp found. Check the connections page.",
        ERR_WAPP_NOT_INITIALIZED: "This WhatsApp session has not been initialized. Check the connections page.",
        ERR_WAPP_CHECK_CONTACT: "Unable to check WhatsApp contact. Check the connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid WhatsApp number.",
        ERR_WAPP_DOWNLOAD_MEDIA: "Unable to download media from WhatsApp. Check the connections page.",
        ERR_WAPP_MAX_REACHED: "You have reached the maximum number of connections allowed in your plan. Contact support for more information.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_INVALID_COMPANY: "Authentication error. Sorry, your login has been deactivated.",
        ERR_SENDING_WAPP_MSG: "Error sending WhatsApp message. Check the connections page.",
        ERR_DELETE_WAPP_MSG: "Unable to delete the WhatsApp message.",
        ERR_OTHER_OPEN_TICKET: "There is already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please log in again.",
        ERR_USER_CREATION_DISABLED: "User creation has been disabled by the administrator.",
        ERR_USER_MAX_REACHED: "You have reached the maximum number of users allowed in your plan. Contact support for more information.",
        ERR_NO_PERMISSION: "You do not have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "There is already a contact with this number.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No message found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error creating message in the database.",
        ERR_CREATING_TICKET: "Error creating ticket in the database.",
        ERR_FETCH_WAPP_MSG: "Error fetching the message on WhatsApp, it might be too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "This color is already in use, choose another one.",
        ERR_WAPP_GREETING_REQUIRED: "The greeting message is required when there is more than one queue.",
        ERR_TAG_INVALID_COMPANYID: "Company ID not identified.",
        ERR_TAG_INVALID_NAME: "Invalid tag name.",
        ERR_TAG_INVALID_COLOR: "Invalid tag color.",
        ERR_TAG_INVALID_UPDATEDBY: "User ID not identified.",
        ERR_TAG_INVALID_STATUS: "Invalid tag status.",
        ERR_TAG_COLOR_ALREADY_EXISTS: "There is already a tag with this color.",
        ERR_TAG_NAME_ALREADY_EXISTS: "There is already a tag with this name.",
        ERR_TAG_MAX_EXCEEDED: "Active tags limit exceeded.",
        ERR_CONCLUDE_FOUND: "Select a completion option.",
        ERR_NO_COMPANY_FOUND: "Company not identified.",
        ERR_NO_CAMPAIGN_FOUND: "Campaign not identified.",
        ERR_NO_CONTACTNUMBER_FOUND: "Invalid/unidentified contact number.",
        ERR_DATABASE_FOUND: "Database not found.",
        ERR_INTEGRATION_ALREADY_EXISTS: "This integration has already been registered.",
        ERR_USER_EMAIL_ALREADY_EXISTS: "There is already a user registered with this email address."
      }

    },
  },
};

export { messages };

