import { Skeleton, Box } from "@mui/material";

export default function SkeletonList({ rows = 3 }) {
	function renderRows() {
		if (!rows || isNaN(rows)) rows = 3;
		const response = [];
		for (let x = 0; x < rows; x++) {
			response.push(
				<Box key={`skeleton_rows_${x}`} style={{ marginBottom: '8px', fontSize: '1rem' }}>
					<Skeleton variant="text" />
				</Box>
			);
		}
		return response;
	}
	return <>{renderRows()}</>;
}