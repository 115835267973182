import clsx from "clsx";
import MicRecorder from "mic-recorder-to-mp3";
import { useContext, useEffect, useRef, useState } from "react";

import {
  Hidden,
  Menu,
  MenuItem
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MicIcon from "@material-ui/icons/Mic";
import MoodIcon from "@material-ui/icons/Mood";
import MoreVert from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";

import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import RecordingTimer from "./RecordingTimer";
import BatchReopenTicketModal from "../BatchReopenTicketModal";
import EmojiDataPicker from "../EmojiDataPicker";
import CustomSwitch from "../CustomSwitch";
import { Box } from "@mui/material";
import { deleteDraft, saveDraft, showDraft } from "../../helpers/Drafts";
import GetUrlTicketPath from "../../helpers/GetUrlTicketPath";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
    [theme.breakpoints.down('959')]: {
      minWidth: 'calc(100% - 170px)',
      maxWidth: 'calc(100% - 170px)',
      width: 'calc(100% - 170px)',
    },
    [theme.breakpoints.down('600')]: {
      minWidth: 'calc(100% - 150px)',
      maxWidth: 'calc(100% - 150px)',
      width: 'calc(100% - 150px)',
    },
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "#f44336",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: "#ffffff",
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          background: "#F1F1F1",
          cursor: "pointer",
        },
      },
    },
  },
}));

const MessageInput = ({ ticketStatus, ticketId, uuid, contactId, whatsapp, ticketQueue }) => {

  const classes = useStyles();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);
  const [userPreset, setUserPreset] = useState(user);
  const history = useHistory();
  const [batchOpen, setBatchOpen] = useState(false);
  const [annotation, setAnnotation] = useState(false);

  useEffect(() => {
    const ticketUUID = GetUrlTicketPath();
    const filterDraft = showDraft(ticketUUID);
    if (filterDraft && filterDraft.text) setInputMessage(filterDraft.text);
    else setInputMessage('');
  }, [uuid]);


  useEffect(() => {
    const getUserSettings = async () => {
      setLoading(true)
      try {
        const { data } = await api.get(`/users/${user.id}`);
        setUserPreset(data)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    };
    getUserSettings()
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  const handleChangeInput = (e) => {
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
    if (e.target.value == '') {
      const ticketUUID = GetUrlTicketPath();
      if (ticketUUID && ticketUUID != 'undefined') deleteDraft(ticketUUID, user.adminId, user.id);
    }
  };

  const handleDrafts = (e) => {

    const ticketUUID = GetUrlTicketPath();
    if (!ticketUUID || ticketUUID == 'undefined') return;

    const text = String(e?.target?.value).trim();
    if (text && text != '' && text != 'undefined') saveDraft(ticketUUID, text, user.adminId, user.id);
  }

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  const handleAddEmoji = (emoji) => {
    setInputMessage((prevState) => prevState + emoji);
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    } else if (e.clipboardData.types.includes('text/plain')) {
      // Verificar se há texto sendo colado
      const pastedText = e.clipboardData.getData('text/plain');
      if (pastedText) {
        handleDrafts({ target: { value: pastedText } });
      }
    }
  };

  const handleUploadMedia = async (e, idTicket = null) => {

    if (ticketQueue == null && !idTicket) {
      handleOpenBatch();
      return;
    }

    setLoading(true);
    e.preventDefault();

    for await (const media of medias) {
      const formData = new FormData();
      formData.append("fromMe", true);
      formData.append("contactId", contactId);
      formData.append("medias", media);
      formData.append("body", media.name);
      formData.append("moduleId", whatsapp.moduleId);
      formData.append("annotation", annotation);
      if (replyingMessage) formData.append("quotedMsg", JSON.stringify(replyingMessage));

      (async () => {
        try {

          // resgatar informações do ticket e criar pré message
          const response = await api.post(`/messages/media/preMessage`, { ticketId, annotation });
          if (response?.data?.messageUUID) formData.append("messageUUID", response?.data?.messageUUID);

          const { data } = await api.post(`/messages/${idTicket != null ? idTicket : ticketId}`, formData);

          if ((data.isNew || idTicket != null) && data?.ticket?.uuid) {
            history.push(`/tickets/${data.ticket.uuid}`);
            document.querySelector('#ticketTabOptions button').click();
          }
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      })();
    }

    setLoading(false);
    clearInputMessage();
  };

  const handleSaveBatch = async (event, status, props) => {

    try {
      const { data } = await api.post("/tickets", { status, queueId: props.queueId, contactId, whatsappId: props.whatsappId });
      // enviar mensagem
      if (medias.length > 0) await handleUploadMedia(event, data.id);
      else await handleSendMessage(data.id);

      handleCloseBatch();
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  }

  const handleOpenBatch = () => {
    setBatchOpen(true);
  }

  const clearInputMessage = () => {
    // limpar rascunho
    const ticketUUID = GetUrlTicketPath();
    deleteDraft(ticketUUID, user.adminId, user.id);
    // resetar input de texto
    setInputMessage("");
    setShowEmoji(false);
    setReplyingMessage(null);
    setMedias([]);
    setRecording(false);
    if (annotation == true) handleAnnotation();
  }

  const handleCloseBatch = () => {
    setBatchOpen(false);
    // resetar input de texto
    clearInputMessage();
    setLoading(false);
  }

  const handleSendMessage = async (idTicket = null) => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    if (ticketQueue == null && !idTicket) {
      handleOpenBatch();
      return;
    }

    let data = inputMessage.trim();
    if (user?.signature && user?.name) data = `*${user.name.trim()}:*\n` + data;

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: data,
      cleanMsg: inputMessage.trim(),
      quotedMsg: replyingMessage,
      contactId,
      moduleId: whatsapp.moduleId,
      annotation
    };

    try {
      const { data } = await api.post(`/messages/${idTicket != null ? idTicket : ticketId}`, message);
      if ((data.isNew || idTicket != null) && data?.ticket?.uuid) {
        history.push(`/tickets/${data.ticket.uuid}`);
        document.querySelector('#ticketTabOptions button').click();
      }
    } catch (err) {
      console.error({err});
      toastError(err);
    }

    clearInputMessage();
    setLoading(false);
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: inputMessage.substring(1) },
        });
        setQuickAnswer(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  //erro ao gravar audio aqui
  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      console.error({err});
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);
      formData.append("moduleId", whatsapp.moduleId);
      if (replyingMessage) formData.append("quotedMsg", JSON.stringify(replyingMessage));

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      console.error({err});
      toastError(err);
    }

    clearInputMessage();
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.Contact?.name}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIconshandleChangeMedias} />
        </IconButton>
      </div>
    );
  };

  const handleAnnotation = async () => {
    setAnnotation(!annotation);
	}

  const getAcceptTypes = (moduleId) => {
    if (moduleId === 2) {
      return [
        'audio/aac',
        'audio/m4a',
        'audio/wav',
        'video/mp4',
        'image/png',
        'image/jpeg',
        'image/gif',
        'video/ogg',
        'video/avi',
        'video/mov',
        'video/webm'
      ].join(', ');
    }
    return undefined;
  };

  if (medias.length > 0)
    return (
      <>
        <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
          <IconButton
            aria-label="cancel-upload"
            component="span"
            onClick={(e) => setMedias([])}
          >
            <CancelIcon className={classes.sendMessageIcons} />
          </IconButton>

          {loading ? (
            <div>
              <CircularProgress className={classes.circleLohandleChangeMediasading} />
            </div>
          ) : (
            <span>
              {medias.map(obj => obj.name).join(', ')}
              {/* <img src={media.preview} alt=""></img> */}
            </span>
          )}
          <IconButton
            aria-label="send-upload"
            component="span"
            onClick={handleUploadMedia}
            disabled={loading || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
          >
            <SendIcon className={classes.sendMessageIcons} />
          </IconButton>
        </Paper>
        <BatchReopenTicketModal open={batchOpen} loading={loading} handleClose={handleCloseBatch} onSave={handleSaveBatch} />
      </>
    );
  else {
    return (
      <>
        <Paper square elevation={0} className={classes.mainWrapper}>
          {replyingMessage && renderReplyingMessage(replyingMessage)}
          <div className={classes.newMessageBox}>
            <Hidden only={["sm", "xs"]}>
  
              <EmojiDataPicker onEmojiSelect={emoji => handleAddEmoji(emoji)} disabled={loading || recording || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')} />

              <input
                type="file"
                id="upload-button"
                disabled={loading || recording || (whatsapp?.status && String(whatsapp.status).toLowerCase() !== 'connected')}
                className={classes.uploadInput}
                onChange={handleChangeMedias}
                accept={getAcceptTypes(whatsapp?.moduleId)}
                multiple={true}
              />
              
              <label htmlFor="upload-button" style={{ margin: '0px' }}>
                <IconButton
                  aria-label="upload"
                  component="span"
                  disabled={loading || recording || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                >
                  <AttachFileIcon className={classes.sendMessageIcons} />
                </IconButton>
              </label>

              <Box style={{width: '100px'}}>
                <CustomSwitch 
                  rightText={i18n.t("messagesInput.annotation")}
                  value={annotation}
                  onClick={handleAnnotation}
                />
              </Box>

            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenuClick}
              >
                <MoreVert></MoreVert>
              </IconButton>
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuItemClick}
              >
                <MenuItem onClick={handleMenuItemClick}>
                  <IconButton
                    aria-label="emojiPicker"
                    component="span"
                    disabled={loading || recording || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                    onClick={(e) => setShowEmoji((prevState) => !prevState)}
                  >
                    <MoodIcon className={classes.sendMessageIcons} />
                  </IconButton>
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick}>
                  <input
                    type="file"
                    id="upload-button"
                    disabled={loading || recording || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                    className={classes.uploadInput}
                    onChange={handleChangeMedias}
                    accept={getAcceptTypes(whatsapp?.moduleId)}
                    multiple={true}
                  />
                  <label htmlFor="upload-button" style={{ margin: '0px' }}>
                    <IconButton
                      aria-label="upload"
                      component="span"
                      disabled={loading || recording || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                    >
                      <AttachFileIcon className={classes.sendMessageIcons} />
                    </IconButton>
                  </label>
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick} style={{ padding: '20px 10px' }}>
                  <Box style={{ width: '100px' }}>
                    <CustomSwitch
                      rightText={i18n.t("messagesInput.annotation")}
                      value={annotation}
                      onClick={handleAnnotation}
                    />
                  </Box>
                </MenuItem>
              </Menu>
            </Hidden>
            <div className={classes.messageInputWrapper}>
              <InputBase
                inputRef={(input) => {
                  input && input.focus();
                  input && (inputRef.current = input);
                }}
                className={classes.messageInput}
                placeholder={whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected' ? i18n.t("messagesInput.placeholderConnection") : i18n.t("messagesInput.placeholderOpen")}
                multiline
                maxRows={5}
                value={inputMessage}
                onChange={(event) => {
                  handleChangeInput(event);
                  handleDrafts(event);
                }}
                disabled={recording || loading || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                onPaste={(e) => { handleInputPaste(e) }}
                onKeyPress={(e) => {
                  if (loading || e.shiftKey) return;
                  else if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
              {typeBar ? (
                <ul className={classes.messageQuickAnswersWrapper}>
                  {quickAnswers.map((value, index) => {
                    return (
                      <li
                        className={classes.messageQuickAnswersWrapperItem}
                        key={index}
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => handleQuickAnswersClick(value.message)}>
                          {`${value.shortcut} - ${value.message}`}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div></div>
              )}
            </div>
            {inputMessage ? (
              <IconButton
                aria-label="sendMessage"
                component="span"
                onClick={() => handleSendMessage()}
                disabled={loading || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
              >
                <SendIcon className={classes.sendMessageIcons} />
              </IconButton>
            ) : recording ? (
              <div className={classes.recorderWrapper}>
                <IconButton
                  aria-label="cancelRecording"
                  component="span"
                  fontSize="large"
                  disabled={loading || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                  onClick={handleCancelAudio}
                >
                  <HighlightOffIcon className={classes.cancelAudioIcon} />
                </IconButton>
                {loading ? (
                  <div>
                    <CircularProgress className={classes.audioLoading} />
                  </div>
                ) : (
                  <RecordingTimer />
                )}

                <IconButton
                  aria-label="sendRecordedAudio"
                  component="span"
                  onClick={handleUploadAudio}
                  disabled={loading || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                >
                  <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                </IconButton>
              </div>
            ) : (
              <IconButton
                aria-label="showRecorder"
                component="span"
                disabled={loading || (whatsapp?.status && String(whatsapp.status).toLowerCase() != 'connected')}
                onClick={handleStartRecording}
              >
                <MicIcon className={classes.sendMessageIcons} />
              </IconButton>
            )}
          </div>
        </Paper>
        <BatchReopenTicketModal open={batchOpen} loading={loading} handleClose={handleCloseBatch} onSave={handleSaveBatch} />
      </>
    );
  }
};

export default MessageInput;
