const indexedDbName = 'Conexa';

// Abrir conexão com o banco
export const openDatabase = (dbName, tableName) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;

            if (!db.objectStoreNames.contains(tableName)) db.createObjectStore(tableName, { keyPath: "id", autoIncrement: true });
        };

        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(event.target.error);
    });
}

// Função para adicionar um item ao banco
export const addItem = async (data, tableName) => {

    const db = await openDatabase(indexedDbName, tableName);
    const transaction = db.transaction(tableName, "readwrite");
    const store = transaction.objectStore(tableName);

    return new Promise((resolve, reject) => {
        const request = store.add(data);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

// Função para resgatar todos os itens de uma tabela
export const getAllItems = async (tableName) => {
    try {
        const db = await openDatabase(indexedDbName, tableName);
        const transaction = db.transaction(tableName, "readonly");
        const store = transaction.objectStore(tableName);

        return new Promise((resolve, reject) => {
            const request = store.getAll();

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
        });
    } catch (error) {
        console.error({ error });
    }
};

// Função para resgatar um item especifico
export const getItemById = async (tableName, id) => {
    const db = await openDatabase(indexedDbName, tableName, id);
    const transaction = db.transaction(tableName, "readonly");
    const store = transaction.objectStore(tableName);

    return new Promise((resolve, reject) => {
        let request = store.getAll();
        request = request.find(req => req.key == id);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

export const updateItem = async (tableName, key, data) => {
    const db = await openDatabase(indexedDbName, tableName);
    const transaction = db.transaction(tableName, "readwrite");
    const store = transaction.objectStore(tableName);

    return new Promise((resolve, reject) => {
        // Recupera o item atual pela chave
        const getRequest = store.get(key);
        getRequest.onsuccess = () => {
            const existingData = getRequest.result;

            if (existingData) {
                // Atualiza o item específico
                const updatedData = { ...existingData, value: data }; // Mescla os dados
                const putRequest = store.put(updatedData);
                putRequest.onsuccess = () => resolve(putRequest.result);
                putRequest.onerror = () => reject(putRequest.error);
            } else {
                reject(new Error(`Item não encontrado para a chave fornecida(${key}).`));
            }
        };
        getRequest.onerror = () => reject(getRequest.error);
    });
};

export const deleteItem = async (tableName, id) => {
    const db = await openDatabase(indexedDbName, tableName);
    const transaction = db.transaction(tableName, "readwrite");
    const store = transaction.objectStore(tableName);

    return new Promise((resolve, reject) => {
        const request = store.delete(id);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

// função para limpar tabelas de outras empresas 
export const cleanUpIndexedDB = async (tableName) => {
    return new Promise((resolve, reject) => {
        // Abrindo o banco de dados em modo de atualização
        const request = indexedDB.open(indexedDbName);

        request.onsuccess = () => {
            const db = request.result;

            // Obtemos todas as tabelas existentes
            const tables = Array.from(db.objectStoreNames);
            const match = tables.findIndex(tb => tb != tableName);

            // adicionar validação para verificar se o tempo de criação da tabela é anterior a data utilizada no env, 
            // afim de limpar o indexedDb sem precisar do auxilio dos usuários
            const timeoutEnv = process.env.REACT_APP_INDEXEDDB_DATE_ALLOWED || null;
            let toUpdateTimeout = false;
            if (timeoutEnv) {
                const timeoutLocalStorage = localStorage.getItem("timeoutIndexedDb");
                if (timeoutLocalStorage && timeoutLocalStorage != "" && timeoutLocalStorage != "undefined") {
                    toUpdateTimeout = new Date(timeoutLocalStorage).getTime() <= new Date(timeoutEnv).getTime();
                } else {
                    toUpdateTimeout = true;
                }
            }

            if (toUpdateTimeout == true || match >= 0 || tables.length == 0) {
                // Fecha o banco para permitir exclusão
                db.close();

                // Exclui o banco de dados
                const deleteRequest = indexedDB.deleteDatabase(indexedDbName);
                localStorage.setItem("timeoutIndexedDb", new Date().toISOString());

                deleteRequest.onsuccess = () => {
                    console.log(`Banco de dados "${indexedDbName}" foi excluído devido ao match.`);
                    resolve();
                };

                deleteRequest.onerror = () => {
                    console.error("Erro ao excluir o banco de dados:", deleteRequest.error);
                    reject(deleteRequest.error);
                };

                return;
            }

            if (db) db.close(); // Fechamos o banco após realizar a operação
            resolve();
        };

        request.onerror = () => {
            console.error("Erro ao abrir o banco de dados:", request.error);
        };
    });
};